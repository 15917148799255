const ARView = ({ svgStyle }) => {
  return (
    <svg
      // width="100"
      // height="100"
      className={svgStyle}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_710_10073)">
        <path
          d="M80 0H20C8.9543 0 0 8.9543 0 20V80C0 91.0457 8.9543 100 20 100H80C91.0457 100 100 91.0457 100 80V20C100 8.9543 91.0457 0 80 0Z"
          fill="black"
        />
        <path
          d="M10.956 36.382C11.4748 36.382 11.9723 36.1759 12.3391 35.8091C12.7059 35.4423 12.912 34.9448 12.912 34.426V19.218C12.9141 17.5462 13.5792 15.9435 14.7613 14.7613C15.9435 13.5792 17.5462 12.9141 19.218 12.912H34.427C34.9458 12.912 35.4433 12.7059 35.8101 12.3391C36.1769 11.9723 36.383 11.4748 36.383 10.956C36.383 10.4372 36.1769 9.93972 35.8101 9.5729C35.4433 9.20608 34.9458 9 34.427 9H19.218C16.5089 9.00291 13.9116 10.0804 11.996 11.996C10.0804 13.9116 9.00291 16.5089 9 19.218V34.427C9.00027 34.9456 9.20646 35.4428 9.57325 35.8095C9.94005 36.1761 10.4374 36.382 10.956 36.382Z"
          fill="white"
        />
        <path
          d="M80.7529 9H65.5439C65.0251 9 64.5276 9.20608 64.1608 9.5729C63.794 9.93972 63.5879 10.4372 63.5879 10.956C63.5879 11.4748 63.794 11.9723 64.1608 12.3391C64.5276 12.7059 65.0251 12.912 65.5439 12.912H80.7529C82.4247 12.9141 84.0274 13.5792 85.2096 14.7613C86.3917 15.9435 87.0568 17.5462 87.0589 19.218V34.427C87.0589 34.9458 87.265 35.4433 87.6318 35.8101C87.9986 36.1769 88.4961 36.383 89.0149 36.383C89.5337 36.383 90.0312 36.1769 90.398 35.8101C90.7648 35.4433 90.9709 34.9458 90.9709 34.427V19.218C90.968 16.5089 89.8905 13.9116 87.9749 11.996C86.0593 10.0804 83.462 9.00291 80.7529 9Z"
          fill="white"
        />
        <path
          d="M34.427 87.0589H19.218C17.5462 87.0568 15.9435 86.3917 14.7613 85.2096C13.5792 84.0274 12.9141 82.4247 12.912 80.7529V65.5439C12.912 65.0251 12.7059 64.5276 12.3391 64.1608C11.9723 63.794 11.4748 63.5879 10.956 63.5879C10.4372 63.5879 9.93972 63.794 9.5729 64.1608C9.20608 64.5276 9 65.0251 9 65.5439V80.7529C9.00318 83.4618 10.0808 86.0588 11.9964 87.9742C13.9119 89.8896 16.5091 90.967 19.218 90.9699H34.427C34.9458 90.9699 35.4433 90.7638 35.8101 90.397C36.1769 90.0302 36.383 89.5327 36.383 89.0139C36.383 88.4951 36.1769 87.9976 35.8101 87.6308C35.4433 87.264 34.9458 87.0589 34.427 87.0589Z"
          fill="white"
        />
        <path
          d="M89.0149 63.5879C88.4961 63.5879 87.9986 63.794 87.6318 64.1608C87.265 64.5276 87.0589 65.0251 87.0589 65.5439V80.7529C87.0568 82.4247 86.3917 84.0274 85.2096 85.2096C84.0274 86.3917 82.4247 87.0568 80.7529 87.0589H65.5439C65.0251 87.0589 64.5276 87.265 64.1608 87.6318C63.794 87.9986 63.5879 88.4961 63.5879 89.0149C63.5879 89.5337 63.794 90.0312 64.1608 90.398C64.5276 90.7648 65.0251 90.9709 65.5439 90.9709H80.7529C83.4618 90.9677 86.0588 89.8901 87.9742 87.9745C89.8896 86.059 90.967 83.4618 90.9699 80.7529V65.5439C90.9699 65.0253 90.764 64.5279 90.3973 64.1611C90.0307 63.7943 89.5335 63.5882 89.0149 63.5879Z"
          fill="white"
        />
        <path
          d="M46.6583 66L42.7883 56.055H30.0533L26.2283 66H22.1333L34.6883 33.735H38.3333L50.8433 66H46.6583ZM37.9733 42.735C37.8833 42.495 37.7333 42.06 37.5233 41.43C37.3133 40.8 37.1033 40.155 36.8933 39.495C36.7133 38.805 36.5633 38.28 36.4433 37.92C36.2933 38.52 36.1283 39.135 35.9483 39.765C35.7983 40.365 35.6333 40.92 35.4533 41.43C35.3033 41.94 35.1683 42.375 35.0483 42.735L31.4033 52.455H41.5733L37.9733 42.735ZM64.1035 33.87C66.7735 33.87 68.9635 34.215 70.6735 34.905C72.4135 35.565 73.7035 36.57 74.5435 37.92C75.3835 39.27 75.8035 40.965 75.8035 43.005C75.8035 44.715 75.4885 46.14 74.8585 47.28C74.2285 48.42 73.4185 49.335 72.4285 50.025C71.4685 50.685 70.4485 51.21 69.3685 51.6L78.1885 66H73.4635L65.6785 52.725H59.2885V66H55.2385V33.87H64.1035ZM63.8785 37.38H59.2885V49.305H64.1035C65.8435 49.305 67.2685 49.08 68.3785 48.63C69.4885 48.15 70.2985 47.46 70.8085 46.56C71.3485 45.66 71.6185 44.535 71.6185 43.185C71.6185 41.775 71.3335 40.65 70.7635 39.81C70.2235 38.97 69.3835 38.355 68.2435 37.965C67.1035 37.575 65.6485 37.38 63.8785 37.38Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_710_10073">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ARView
