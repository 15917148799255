import {
  Plane,
  useGLTF,
  Backdrop,
  useHelper,
  useAnimations,
} from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { BoxHelper, MeshStandardMaterial, Vector3 } from 'three'
import ModelDimensions from '../components/ModelDimensions'
import { baseUrl, useHud } from '../store'
import ModelAnimations from './ModelAnimations'
import { sendEvent } from '../helpers/google-analytics'

const ProductModel = ({
  productCode,
  modelPointerDownHandler,
  setHovered,
  setShowProductFeature,
  setShowTapText,
  productDimension,
  isDark,
  modalData,
  dimension,
  setDimension,
  dimensionStatus,
  setDimensionStatus,
  controls,
  setShowHud,
  showTapText,
  showHud,
  showProductFeature,
}) => {
  const productModel = useRef()
  const groupRef = useRef()
  const count = useRef()
  count.current = 0
  const [size, setSize] = useState({
    maxX: 0,
    minX: 0,
    maxY: 0,
    minY: 0,
    maxZ: 0,
    minZ: 0,
  })
  // useHelper(productModel, BoxHelper, 'cyan')
  const model = useGLTF(
    baseUrl + `/threed-model/model/${productCode}/model.glb`
  )

  /** <---------- Model Animation Region Start ------------>  */
  const { animations } = model
  const { actions } = useAnimations(animations, productModel)
  const Animation = useHud()
  const [isPlaying, setIsPlaying] = useState(false)
  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    if (model) {
      Object.keys(actions)?.map((anim) => {
        if (!actions[anim]) return
        setAnimation(actions[anim])
      })
    }
  }, [model])

  const handleClick = () => {
    if (!Animation.isPlaying && animation != null) {
      sendEvent(
        `Model animation played`,
        'Model Animation',
        'Product Model Animation'
      )
      Animation.setIsPlaying(true)
      // console.log(`action:`, actions)
      if (dimension) {
        setDimension(false)
        setDimensionStatus('closed')
      }
      animation.reset().play()
      setShowProductFeature(false)
      setTimeout(() => {
        setShowHud(true)
      }, 2000)
    }
  }

  useFrame((_, delta) => {
    if (Animation.isPlaying) {
      animation.setLoop(THREE.LoopOnce, 1)
      animation.clampWhenFinished = true
      if (showProductFeature) {
        setShowProductFeature(false)
      }
      if (showTapText === 'active') {
        setShowTapText('deactive')
      }
      if (animation.time === animation.getClip().duration) {
        Animation.setIsPlaying(false)
        if (dimensionStatus === 'closed') {
          setDimensionStatus('open')
          setDimension(true)
        }
      }
    }
  })

  /** <---------- Model Animation Region End ------------>  */

  useEffect(() => {
    const box = new THREE.Box3().setFromObject(productModel.current)

    const center = new Vector3(0, 0, 0)
    box.getCenter(center)

    if (controls.current) {
      if (
        modalData.lookAtX != 0 ||
        modalData.lookAtY != 0 ||
        modalData.lookAtZ != 0
      ) {
        controls.current.target.set(
          modalData.lookAtX,
          modalData.lookAtY,
          modalData.lookAtZ
        )
      } else {
        controls.current.target = center
      }
    }
    // controls.current.target = center
    // console.log(
    //   `center: `,
    //   modalData.lookAtX,
    //   modalData.lookAtY,
    //   modalData.lookAtZ
    // )
    setSize({
      ...size,
      maxX: box.max.x,
      minX: box.min.x,
      maxY: box.max.y,
      minY: box.min.y,
      maxZ: box.max.z,
      minZ: box.min.z,
    })
    // console.log('width max: ', box.max.x, 'width min: ', box.min.x)
    // console.log('height max: ', box.max.y, 'height min: ', box.min.y)
    // console.log('depth max: ', box.max.z, 'depthe min: ', box.min.z)
  }, [productDimension])

  //! Don't touch this useEffect! not related to dimensions
  // useEffect(() => {
  //   function pointerDown() {
  //     // let clickTotal = 0
  //     function handlePointerDown() {
  //       // clickTotal++
  //       // console.log(
  //       // 	'pointer down',
  //       // 	// showTapText,
  //       // 	'clickTotal ProductModel',
  //       // 	clickTotal
  //       // )
  //       // if (clickTotal === 1) {
  //       setShowTapText(false)
  //       if (model.animations.length) {
  //         setTimeout(() => {
  //           setShowProductFeature(true)
  //         }, 2000)
  //       } else {
  //         setTimeout(() => {
  //           setShowHud(true)
  //         }, 2000)
  //       }
  //       // }
  //     }
  //     return handlePointerDown
  //   }
  //   const canvas = document.getElementsByTagName('canvas')[0]
  //   canvas.addEventListener('pointerdown', pointerDown())

  //   return () => {
  //     canvas.removeEventListener('pointerdown', pointerDown())
  //   }
  // }, [])

  useEffect(() => {
    if (showTapText === 'active') {
      controls.current.addEventListener('change', onControlStart, false)
    }

    // controls.current.addEventListener('end', onControlEnd, false)

    return function cleanup() {
      controls.current.removeEventListener('change', onControlStart, false)
      // controls.current.removeEventListener('end', onControlEnd, false)
    }
  }, [showTapText])

  function onControlStart() {
    setShowTapText('deactive')
    // console.log('control start' + showHud)
    if (model.animations.length) {
      setTimeout(() => {
        setShowProductFeature(true)
      }, 1000)
    } else {
      setTimeout(() => {
        setShowHud(true)
      }, 1000)
    }
  }
  return (
    model && (
      <>
        <Plane
          args={[100, 100, 10, 10]}
          scale={[8, 8, 8]}
          rotation-x={-Math.PI / 2}
          position-y={modalData.offsetY - 0.01}
          receiveShadow
        >
          <meshPhysicalMaterial
            metalness={0}
            roughness={`${isDark ? 0.9 : 0.5}`}
            emissive={`${isDark ? 'rgb(0,0,0)' : '#fff'}`}
            color={`${isDark ? 'rgb(81,81,97)' : 'rgb(250,250,250)'}`}
            // emissive={`${isDark ? 'rgb(152,153,165)' : '#fff'}`} 72,72,86 81,81,97
            // emissive={`${isDark ? 'rgb(152,153,165)' : '#fff'}`}
            // color={`${isDark ? 'rgb(116,118,139)' : 'rgb(250,250,250)'}`}
          />
        </Plane>
        <group
          dispose={null}
          ref={groupRef}

          // scale={[30, 30, 30]}
          // position={[0, -15, 0]}
        >
          <primitive
            ref={productModel}
            // scale={35}
            object={model.scene}
            onClick={handleClick}
            // onClick={modelPointerDownHandler.current}
            onPointerOver={() => {
              animation != null && setHovered(true)
            }}
            onPointerOut={() => {
              animation != null && setHovered(false)
            }}
            scale={40}
            position={[0, modalData.offsetY, 0]}
          />
          {/* */}

          {/* Model dimension */}
          {productDimension && (
            <ModelDimensions
              minX={size.minX}
              maxX={size.maxX}
              minY={size.minY}
              maxY={size.maxY}
              minZ={size.minZ}
              maxZ={size.maxZ}
              modelRef={productModel}
              isDark={isDark}
              modalData={modalData}
            />
          )}
        </group>
        {/* <ModelAnimations
          model={model}
          modelPointerDownHandler={modelPointerDownHandler}
          setShowProductFeature={setShowProductFeature}
          setShowHud={setShowHud}
          setTooltipTutorial={setTooltipTutorial}
          animation={animation}
          setAnimation={setAnimation}
          groupRef={groupRef}
        /> */}
      </>
    )
  )
}

export default ProductModel
