import { useEffect, useRef, useState } from 'react'
import DynamicSkybox from './DynamicSkybox'
import { baseUrl, useModal } from '../store'
import {
  ContactShadows,
  Environment,
  Html,
  OrbitControls,
  useProgress,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { Suspense } from 'react'
import { folder, useControls } from 'leva'
import { ErrorBoundary } from 'react-error-boundary'

const ModelViewer = ({ ...props }) => {
  const modal = useModal()
  const model = useGLTF(`${baseUrl}/threed-model/model/${props.id}/model.glb`)
  const ref = useRef()

  const {
    hdri,
    clampLeft_PI_by,
    clampRight_PI_by,
    day,
    night,
    offsetY,
    minZoom,
    maxZoom,
    _x,
    _y,
    _z,
    mode,
    posX,
    posY,
    posZ,
  } = useControls({
    mode: {
      value: props.modeValues[0],
      options: props.modeValues,
    },
    clampLeft_PI_by: {
      value: props.view && props.view.maxLeft ? props.view.maxLeft : 0,
      step: 1,
      min: -12,
      max: 12,
    },
    clampRight_PI_by: {
      value: props.view && props.view.maxRight ? props.view.maxRight : 0,
      step: 1,
      max: 12,
      min: -12,
    },
    offsetY: {
      value: props.offsetY || 0,
    },
    orbitControls: folder({
      minZoom: {
        value: props.minZoom || 20,
      },
      maxZoom: {
        value: props.maxZoom || 150,
      },
      lookAt: folder({
        _x: {
          value: props.lookAtX || 0,
        },
        _y: {
          value: props.lookAtY || 0,
        },
        _z: {
          value: props.lookAtZ || 0,
        },
      }),
    }),
    dimensionPosition: folder({
      posX: {
        value: props.dimensionPositionX || 0,
      },
      posY: {
        value: props.dimensionPositionY || 0,
      },
      posZ: {
        value: props.dimensionPositionZ || 0,
      },
    }),
    hdri: folder({
      hdri: {
        value: props.skyboxes[0],
        options: props.skyboxes,
      },
      day: {
        // value: props.skyboxes[0],
        value: props.dayNightHDRI['dayHDRI']
          ? props.dayNightHDRI['dayHDRI']
          : props.skyboxes[0],
        options: props.skyboxes,
      },
      night: {
        // value: props.skyboxes[0],
        value: props.dayNightHDRI['nightHDRI']
          ? props.dayNightHDRI['nightHDRI']
          : props.skyboxes[0],
        options: props.skyboxes,
      },
    }),
  })
  const CONTACT_SHADOW_DATA = {
    opacity: {
      value: props.contactShadow.opacity || 0.5,
      min: 0,
      max: 1,
    },
    scale: {
      value: props.contactShadow.scale || 20,
      min: 1,
      max: 100,
      step: 1,
    },
    blur: {
      value: props.contactShadow.blur || 3,
      min: 0,
      max: 10,
      step: 0.1,
    },
    position: folder({
      x: {
        value: props.contactShadow.x || 0,
      },
      y: {
        value: props.contactShadow.y || 0,
      },
      z: {
        value: props.contactShadow.z || 0,
      },
    }),
  }
  const isInitialMount = useRef(true)
  const { opacity, scale, blur, x, y, z } = useControls(
    'Contact Shadow',
    CONTACT_SHADOW_DATA
  )
  const { gl } = useThree()
  const { camera } = useThree()
  const controls = useRef()

  useEffect(() => {
    switch (mode) {
      case 'Desktop':
        props.setMode('Desktop')
        break
      case 'Landscape':
        props.setMode('Landscape')
        break
      case 'Portrait':
        props.setMode('Portrait')
        break
      default:
        props.setMode('Desktop')
        break
    }
  }, [mode])

  const orbitChanged = () => {
    switch (mode) {
      case 'Desktop':
        props.setView((state) => ({
          ...state,
          position: camera.position,
          zoom: camera.zoom,
          lookAt: controls.current?.target,
        }))
        break
      case 'Landscape':
        props.setLandscapeView((state) => ({
          ...state,
          position: camera.position,
          zoom: camera.zoom,
          lookAt: controls.current?.target,
        }))
        break
      case 'Portrait':
        props.setPortraitView((state) => ({
          ...state,
          position: camera.position,
          zoom: camera.zoom,
          lookAt: controls.current?.target,
        }))
        break
      default:
        props.setView((state) => ({
          ...state,
          position: camera.position,
          zoom: camera.zoom,
          lookAt: controls.current?.target,
        }))
        break
    }
  }
  useEffect(() => {
    // || !props.view.lookAt
    switch (mode) {
      case 'Desktop':
        if (!props.view || props.view === {} || !props.view.lookAt) return
        controls.current.target.set(...Object.values(props.view.lookAt))
        camera.position.set(...Object.values(props.view.position))
        camera.zoom = props.view.zoom
        break
      case 'Landscape':
        if (
          !props.landscapeView ||
          props.landscapeView === {} ||
          !props.landscapeView.lookAt
        )
          return
        controls.current.target.set(
          ...Object.values(props.landscapeView.lookAt)
        )
        camera.position.set(...Object.values(props.landscapeView.position))
        camera.zoom = props.landscapeView.zoom
        break
      case 'Portrait':
        if (
          !props.portraitView ||
          props.portraitView === {} ||
          !props.portraitView.lookAt
        )
          return
        controls.current.target.set(...Object.values(props.portraitView.lookAt))
        camera.position.set(...Object.values(props.portraitView.position))
        camera.zoom = props.portraitView.zoom
        break
      default:
        controls.current.target.set(...Object.values(props.view.lookAt))
        camera.position.set(...Object.values(props.view.position))
        camera.zoom = props.view.zoom
        break
    }
  }, [mode])

  // useEffect(() => {
  //   console.log(
  //     'mode data',
  //     props.landscapeView,
  //     props.view,
  //     props.portraitView
  //   )
  // }, [props.landscapeView, props.view, props.portraitView])

  useEffect(() => {
    switch (mode) {
      case 'Desktop':
        props.setView((state) => ({
          ...state,
          maxLeft: clampLeft_PI_by,
          maxRight: clampRight_PI_by,
        }))
        break
      case 'Landscape':
        props.setLandscapeView((state) => ({
          ...state,
          maxLeft: clampLeft_PI_by,
          maxRight: clampRight_PI_by,
        }))
        break
      case 'Portrait':
        props.setPortraitView((state) => ({
          ...state,
          maxLeft: clampLeft_PI_by,
          maxRight: clampRight_PI_by,
        }))
        break
      default:
        props.setView((state) => ({
          ...state,
          maxLeft: clampLeft_PI_by,
          maxRight: clampRight_PI_by,
        }))
        break
    }
  }, [clampLeft_PI_by, clampRight_PI_by, mode])

  gl.setClearColor('grey')

  useEffect(() => {
    // console.log(
    // 	'opacity, scale, blur, x, y, z',
    // 	opacity,
    // 	scale,
    // 	blur,
    // 	x,
    // 	y,
    // 	z
    // )
    if (isInitialMount.current) {
      isInitialMount.current = false
      if (Object.keys(props.contactShadow).length === 0)
        props.setContactShadow({ opacity, scale, blur, x, y, z })
    } else {
      props.setContactShadow({ opacity, scale, blur, x, y, z })
    }
  }, [opacity, scale, blur, x, y, z])

  useEffect(() => {
    // console.log('hdr: ' + day + 'night: ' + night + 'hdri: ' + hdri)
    const customDayNightHDRI = {
      dayHDRI: day,
      nightHDRI: night,
    }
    props.setDayNightHDRI({ ...customDayNightHDRI })
  }, [day, night])

  useEffect(() => {
    props.setOffsetY(offsetY)
  }, [offsetY])

  useEffect(() => {
    props.setDimensionPositionX(posX)
    props.setDimensionPositionY(posY)
    props.setDimensionPositionZ(posZ)
  }, [posX, posY, posZ])

  // useEffect(() => {
  //   console.log('sms dimension pos:', props.dimensionPositionY)
  // }, [props.dimensionPositionY])

  useEffect(() => {
    props.setMinZoom(minZoom)
    props.setMaxZoom(maxZoom)
  }, [minZoom, maxZoom])

  useEffect(() => {
    if (controls.current) {
      props.setLookAtX(_x)
      props.setLookAtY(_y)
      props.setLookAtZ(_z)
    }
  }, [_x, _y, _z])

  return (
    <>
      {/* <DynamicSkybox hdri={hdri} /> */}
      <OrbitControls
        onEnd={orbitChanged}
        minAzimuthAngle={Math.PI / clampLeft_PI_by}
        maxAzimuthAngle={Math.PI / clampRight_PI_by}
        enableDamping={false}
        ref={controls}
        enablePan={false}
        minPolarAngle={-Math.PI / 2}
        maxPolarAngle={Math.PI / 1.9}
        minDistance={minZoom}
        maxDistance={maxZoom}
        target={[props.lookAtX, props.lookAtY, props.lookAtZ]}
      />
      <ContactShadows
        opacity={opacity}
        scale={scale}
        blur={blur}
        position={[x, y, z]}
        depthWrite={true}
      />
      <Environment files={baseUrl + '/skybox/' + hdri} />
      {/* fetch local skyboxes */}
      {/* <Environment files={'/skybox/' + hdri} /> */}
      <primitive
        ref={ref}
        scale={40}
        object={model.scene}
        position-y={offsetY}
      />
    </>
  )
}

const Loader = () => {
  const { progress } = useProgress()
  return (
    <Html fullscreen>
      <h1>{progress.toFixed(3) + '%'}</h1>
    </Html>
  )
}
const Fallback = () => <p>bad glb</p>

const CMSModelViewer = ({
  view,
  setView,
  skyboxes,
  contactShadow,
  setContactShadow,
  setDayNightHDRI,
  dayNightHDRI,
  offsetY,
  setOffsetY,
  minZoom,
  maxZoom,
  setMinZoom,
  setMaxZoom,
  lookAtX,
  lookAtY,
  lookAtZ,
  setLookAtX,
  setLookAtY,
  setLookAtZ,
  modeValues,
  landscapeView,
  setLandscapeView,
  portraitView,
  setPortraitView,
  setMode,
  dimensionPositionX,
  dimensionPositionY,
  dimensionPositionZ,
  setDimensionPositionX,
  setDimensionPositionY,
  setDimensionPositionZ,
}) => {
  const modal = useModal()
  const controls = useRef()
  return (
    <>
      <ErrorBoundary FallbackComponent={() => <p>bad glb</p>}>
        <Canvas>
          <Suspense fallback={<Loader />}>
            {
              <ModelViewer
                controls={controls}
                view={view}
                setView={setView}
                skyboxes={skyboxes}
                id={modal.data.productCode}
                contactShadow={contactShadow}
                setContactShadow={setContactShadow}
                setDayNightHDRI={setDayNightHDRI}
                dayNightHDRI={dayNightHDRI}
                offsetY={offsetY}
                setOffsetY={setOffsetY}
                minZoom={minZoom}
                setMinZoom={setMinZoom}
                maxZoom={maxZoom}
                setMaxZoom={setMaxZoom}
                lookAtX={lookAtX}
                lookAtY={lookAtY}
                lookAtZ={lookAtZ}
                setLookAtX={setLookAtX}
                setLookAtY={setLookAtY}
                setLookAtZ={setLookAtZ}
                modeValues={modeValues}
                landscapeView={landscapeView}
                setLandscapeView={setLandscapeView}
                portraitView={portraitView}
                setPortraitView={setPortraitView}
                setMode={setMode}
                dimensionPositionX={dimensionPositionX}
                dimensionPositionY={dimensionPositionY}
                dimensionPositionZ={dimensionPositionZ}
                setDimensionPositionX={setDimensionPositionX}
                setDimensionPositionY={setDimensionPositionY}
                setDimensionPositionZ={setDimensionPositionZ}
              />
            }
          </Suspense>
        </Canvas>
      </ErrorBoundary>
    </>
  )
}

export default CMSModelViewer
