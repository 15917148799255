import React from 'react'

function Measure({ svgStyle }) {
  return (
    <svg
      // width="106"
      // height="106"
      // width="1.5em"
      // height="1.5em"
      className={svgStyle}
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_710_10083)">
        <rect x="2" y="2" width="100" height="100" rx="20" fill="black" />
        <g clip-path="url(#clip0_710_10083)">
          <path
            d="M21.7237 53.0939C21.1252 53.4396 20.6885 54.0089 20.5097 54.6765C20.3308 55.3442 20.4245 56.0555 20.77 56.6541L32.3532 76.7181C32.5244 77.0145 32.7523 77.2744 33.0239 77.4827C33.2955 77.6911 33.6054 77.8439 33.9361 77.9325C34.2668 78.021 34.6116 78.0436 34.951 77.9989C35.2904 77.9541 35.6176 77.843 35.914 77.6718L82.2757 50.9055C82.5722 50.7343 82.832 50.5064 83.0403 50.2348C83.2487 49.9632 83.4015 49.6532 83.4901 49.3226C83.5786 48.9919 83.6012 48.6471 83.5565 48.3077C83.5118 47.9683 83.4006 47.6411 83.2294 47.3446L71.6463 27.2806C71.4751 26.9842 71.2472 26.7244 70.9756 26.516C70.704 26.3077 70.394 26.1548 70.0634 26.0663C69.7327 25.9777 69.3878 25.9552 69.0485 25.9999C68.7091 26.0446 68.3818 26.1558 68.0854 26.327L21.7237 53.0939ZM26.5875 56.3048L68.4347 32.1444L77.4119 47.6939L74.3961 49.4352L71.0317 43.6063L68.7745 44.9099L72.1389 50.7381L68.6873 52.7314L65.3223 46.9025L63.065 48.2061L66.43 54.0343L62.9823 56.0251L59.6172 50.1962L57.3599 51.4998L60.725 57.328L57.2734 59.3213L52.5972 51.2225L50.3399 52.5255L55.0162 60.6242L51.5639 62.6175L48.1989 56.7886L45.9416 58.0922L49.3067 63.9204L45.8589 65.9105L42.4945 60.0823L40.2372 61.3859L43.6016 67.2141L40.15 69.2067L36.785 63.3785L34.5277 64.6821L37.8928 70.5103L35.5648 71.8543L26.5875 56.3048Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_710_10083"
          x="0"
          y="0"
          width="106"
          height="106"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_710_10083"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_710_10083"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_710_10083">
          <rect
            width="63.1579"
            height="63.1579"
            fill="white"
            transform="translate(20.4209 20.4209)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Measure
