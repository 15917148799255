import { useEffect, useState } from 'react'
import { baseUrl, useModal, useQR } from '../store'
import CMSLoading from './CMSLoading'
import Modal from './Modal'
import QRModal from './QRModal'
import { css, StyleSheet } from 'aphrodite'
import commonStyles from './commonStyles'
import axios from 'axios'
import ItemsContainer from './ItemsContainer'
import Create from './Create'
import AuthUserUI from './AuthUserUI'
import { Box, Button, Container, Typography } from '@mui/material'

const CMS = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(0)
  const [changed, setChanged] = useState(true)
  const [skyboxes, setSkyboxes] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const qr = useQR()
  const [validated, setValidated] = useState(false)
  const modal = useModal()
  const [editProduct, seteditProduct] = useState(false)

  const toggleChanged = () => {
    setChanged((state) => !state)
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        let response = await axios.get(`${baseUrl}/threed-model`)
        // console.log('data: ', response.data)
        response.data = response.data
          .map((x) => ({
            ...x,
            updatedAt: x.updatedAt.replace(/T/, ' ').replace(/\..+/, ''),
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
        setData(response.data)
      } catch (err) {
        console.error(err)
      }
    }

    fetch()

    const fetchHdri = async () => {
      try {
        let response = await axios.get(baseUrl + '/skybox')
        console.log('skyboxes in cms', skyboxes)
        setSkyboxes(response.data.map((x) => x.name))
      } catch (err) {}
    }
    fetchHdri()
  }, [changed])

  const filteredData = (value) => {
    let productCodeMatchedValues = data.filter((item) =>
      item['productCode'].includes(value)
    )
    // console.log('productCodeMatchedValues', productCodeMatchedValues)
    return productCodeMatchedValues
  }

  // useEffect(() => {
  // 	// fetching static skyboxes from the local web app
  // 	setSkyboxes(['whiteroom', 'darkroom'])
  // }, [])

  const handleModelClick = async () => {
    modal.setShow(true)
  }

  return validated ? (
    <>
      <Modal
        skyboxes={skyboxes}
        setLoading={setLoading}
        toggleChanged={toggleChanged}
        data={data}
      />
      {qr.url && <QRModal />}
      <CMSLoading loading={loading} />
      <div className={css(styles.container)}>
        <div className={css(styles.flex)}>
          <div className={css(styles.items)}>
            <div className={css(styles.heading)}>
              <h1 className={css(commonStyles.heading)}>Godrej-Interio</h1>
            </div>
            <form className={css(styles.form)}>
              <label className={css(styles.label)}>
                search product code &nbsp;
              </label>
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={css(styles.input)}
              />
            </form>
            <Container sx={customStyles.metaContainer}>
              <Typography>Click on any Product to Edit/View it</Typography>
              {modal.data._id && (
                <Box sx={customStyles.btnsContainer}>
                  <Button
                    variant="contained"
                    onClick={() => seteditProduct(!editProduct)}
                  >
                    {editProduct ? 'Create Product' : 'Edit Product'}
                  </Button>
                  <Button variant="contained" onClick={handleModelClick}>
                    View Model
                  </Button>
                </Box>
              )}
            </Container>

            <ItemsContainer
              setLoading={setLoading}
              changed={changed}
              data={filteredData(searchQuery)}
              setData={setData}
            />
          </div>
          <div style={{ width: '33%' }}>
            <Create
              skyboxes={skyboxes}
              setLoading={setLoading}
              toggleChanged={toggleChanged}
              data={data}
              editProduct={editProduct}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <AuthUserUI setValidated={setValidated} />
  )
}

export default CMS

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
  heading: {
    textAlign: 'center',
    margin: '4%',
  },
  items: {
    width: '65%',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  flex: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    oveflow: 'hidden',
    '@media (max-width: 900px)': {
      display: 'block',
      overflow: 'auto',
    },
  },

  form: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    marginLeft: '50vw',
    transform: 'translateX(-50%)',
    marginTop: '-2%',
    marginBottom: '2%',
  },
  label: {
    display: 'block',
    fontSize: '1.2rem',
    marginTop: '1%',
  },
  input: {
    display: 'block',
    fontSize: '1rem',
    width: '20%',
  },
})

const customStyles = {
  metaContainer: {
    display: 'flex',
    // justifyContent: 'f',
    justifyContent: 'space-between',
    paddingRight: '50px !important',
    marginBottom: 2,
    width: '92%',
    alignItems: 'center',
  },
  btnsContainer: {
    display: 'flex',
    gap: 3,
  },
}
