import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { Color } from 'three'

const Background = ({ isDark }) => {
  const { scene } = useThree()

  useEffect(() => {
    scene.background = isDark
      ? new Color('rgba(152,153,165)') //152,153,165
      : new Color('rgb(247, 247, 247)')
  }, [scene, isDark])

  return null
}

export default Background
