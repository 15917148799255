import logo from './logo.svg'
import './App.css'
import CMS from './components/CMS'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SingleProduct from './product/SingleProduct'
import { activateGA } from './helpers/google-analytics'
import { gaConfig } from './constants'

activateGA(gaConfig.code)

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<CMS />} />
          <Route path="/product/:productCode" element={<SingleProduct />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
