import React from 'react'

function ErrorIcon() {
  return (
    <svg
      // width="268"
      // height="268"
      viewBox="0 0 268 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M134 189.834C137.164 189.834 139.818 188.761 141.962 186.617C144.106 184.473 145.174 181.823 145.166 178.667C145.166 175.503 144.094 172.849 141.95 170.705C139.806 168.561 137.156 167.493 134 167.5C130.836 167.5 128.182 168.572 126.038 170.716C123.894 172.86 122.826 175.51 122.833 178.667C122.833 181.831 123.905 184.485 126.049 186.629C128.193 188.773 130.843 189.841 134 189.834ZM122.833 145.167H145.166V78.1668H122.833V145.167ZM134 245.667C118.552 245.667 104.036 242.734 90.4497 236.868C76.8636 231.001 65.0455 223.047 54.9955 213.004C44.9455 202.954 36.9911 191.136 31.1323 177.55C25.2736 163.964 22.3405 149.447 22.333 134C22.333 118.553 25.2661 104.036 31.1323 90.4502C36.9986 76.8641 44.953 65.046 54.9955 54.996C65.0455 44.946 76.8636 36.9916 90.4497 31.1328C104.036 25.2741 118.552 22.3409 134 22.3335C149.447 22.3335 163.964 25.2666 177.55 31.1328C191.136 36.9991 202.954 44.9534 213.004 54.996C223.054 65.046 231.012 76.8641 236.878 90.4502C242.744 104.036 245.674 118.553 245.666 134C245.666 149.447 242.733 163.964 236.867 177.55C231.001 191.136 223.046 202.954 213.004 213.004C202.954 223.054 191.136 231.012 177.55 236.879C163.964 242.745 149.447 245.674 134 245.667Z"
        fill="black"
      />
    </svg>
  )
}

export default ErrorIcon
