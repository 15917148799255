import { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import commonStyles from './commonStyles'
import axios from 'axios'
import { baseUrl, useModal } from '../store'
import { Box, Button, TextField, Typography } from '@mui/material'

const Create = (props) => {
  const [info, setInfo] = useState({})
  const [actualGlb, setActualGlb] = useState({})
  const [actualUsdz, setActualUsdz] = useState({})
  const [submit, setSubmit] = useState(false)
  const [skyboxInfo, setSkyboxInfo] = useState({})
  const [actualHdr, setActualHdr] = useState()
  const [actualImg, setImg] = useState()
  const modal = useModal()

  // console.log('modal in create', modal.data)

  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value })
    setActualGlb(e.target.files[0])
  }

  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value })
    setActualUsdz(e.target.files[0])
  }

  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value })
    setImg(e.target.files[0])
  }

  useEffect(() => {
    if (props.editProduct) {
      setSubmit(true)
    } else {
      if (
        info.glb &&
        info.usdz &&
        // info.name &&
        // info.dimensionX &&
        // info.dimensionY &&
        // info.dimensionZ &&
        info.productCode &&
        // info.tags &&
        !props.data.some((item) => item.productCode === info.productCode)
      ) {
        setSubmit(true)
      } else {
        setSubmit(false)
      }
    }
  }, [info])

  const handleSubmit = async (e) => {
    e.preventDefault()
    props.setLoading(1)
    //console.log({...info,file:actualGlb})
    // info['glb'] = actualGlb
    // info.usdz = actualUsdz
    // setInfo({
    // 	...info,
    // 	glb: actualGlb,
    // 	usdz: actualUsdz,
    // 	thumbnail: actualImg,
    // })
    // setInfo({...info, glb:actualUsdz})
    let formData = new FormData()
    for (let i in info) {
      // //console.log(i, info[i])
      // if (i === 'thumbnail') formData.append(i, actualImg)
      // else if (i === 'tags') formData.append(i, info[i].split(/[\s,]+/))
      // else
      if (i === 'thumbnail') {
        formData.append(i, actualImg)
      } else if (i === 'glb') {
        formData.append(i, actualGlb)
      } else if (i === 'usdz') {
        formData.append(i, actualUsdz)
      } else {
        formData.append(i, info[i])
      }
    }
    formData.append('dayHDRI', props.skyboxes[0])
    formData.append('nightHDRI', props.skyboxes[0])
    // console.log(
    // 	'-----------------------output formData-----------------------'
    // )
    // for (let val of formData.entries()) {
    // 	console.log(val[0], ', ', val[1], typeof val[1])
    // 	// if (val[1] instanceof File) {
    // 	// 	console.log('val instanceof file list', val[1])
    // 	// }
    // }
    // console.log('info after appending formData', info)
    // info['glb'] = ''
    // info.usdz = ''
    // info.thumbnail = ''
    // console.log(formData)
    try {
      let res = await axios.post(`${baseUrl}/product`, formData)
      // console.log('model uploaded res', res)
      props.setLoading(2)
    } catch (err) {
      console.log('err while uploading model', err)
      props.setLoading(-1)
    }
    setInfo('')
    props.toggleChanged()
  }

  const handleSkyboxSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('name', skyboxInfo.name)
    formData.append('hdr', actualHdr)
    try {
      props.setLoading(1)
      await axios.post(baseUrl + '/skybox', formData)
      props.setLoading(2)
    } catch (err) {
      props.setLoading(-1)
      //console.log(err)
    }
    props.toggleChanged()
  }

  const handleSkyboxChange = (e) => {
    setActualHdr(e.target.files[0])
    setSkyboxInfo({ ...skyboxInfo, hdr: e.target.value })
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    // console.log('sending packet')
    // props.setLoading(1)
    // if (actualGlb) info['glb'] = actualGlb
    // else delete info.glb
    // if (actualUsdz) info.usdz = actualUsdz
    // else delete info.usdz
    // if (actualImg) info.thumbnail = actualImg
    // else delete info.thumbnail
    let formData = new FormData()

    for (let i in info) {
      console.log('info: ', i)
      // console.log('key i in info', i, info[i])
      // if (i === 'view' || i === 'contactShadow') {
      // 	formData.append(i, JSON.stringify(info[i]))
      // 	continue
      // }
      // if (i === 'tags') console.log(i, info[i], info[i].split(/[\s,]+/))
      // if (i === 'thumbnail') formData.append(i, actualImg)
      // else if (i === 'tags') formData.append(i, info[i].split(/[\s,]+/))
      // else formData.append(i, info[i])
      // console.log('actualImg', actualImg, actualImg.length)
      if (i === 'thumbnail') {
        console.log('append actualImg', actualImg)
        if (actualImg instanceof File) formData.append(i, actualImg)
      } else if (i === 'glb') {
        formData.append(i, actualGlb)
      } else if (i === 'usdz') {
        formData.append(i, actualUsdz)
      } else if (i === 'view' || i === 'contactShadow') {
        formData.append(i, JSON.stringify(info[i]))
      } else {
        // console.log('i getting appended', i)
        formData.append(i, info[i])
      }
    }

    // console.log('info in edit submit', info)
    // for (let val of formData.entries()) {
    // 	console.log(val[0], ', ', val[1], typeof val[1])
    // }
    // info.glb = ''
    // info.usdz = ''
    // info.thumbnail = ''
    // console.log(
    // 	'-----------------------output formData-----------------------'
    // )
    // for (let val of formData.entries()) {
    // 	console.log(val[0], ', ', val[1])
    // }
    // console.log(
    // 	'-----------------------end of output formData-----------------------'
    // )
    setInfo('')

    props.setLoading(1)

    try {
      let res = await axios.put(
        `${baseUrl}/product/edit/${modal.data._id}`,
        formData
      )
      console.log('product edited with res', res)
      props.setLoading(2)
    } catch (err) {
      console.log('err while editing product', err)
      props.setLoading(-1)
    }

    props.toggleChanged()

    // props.toggleChanged()
  }

  useEffect(() => {
    if (props.editProduct) {
      setInfo({
        ...modal.data,
        id: modal.data.productCode,
        glb: '',
        usdz: '',
        thumbnail: '',
        dimensionX: modal.data.dimensionX,
        dimensionY: modal.data.dimensionY,
        dimensionZ: modal.data.dimensionZ,
        // offsetY: modal.data.offsetY,
        // minZoom: modal.data.minZoom,
        // maxZoom: modal.data.maxZoom,
        // lookAtX: modal.data.lookAtX,
        // lookAtY: modal.data.lookAtY,
        // lookAtZ: modal.data.lookAtZ,
        // features: '',
        // collectionName: '',
        // finish: '',
        // tags: '',
        // description: '',
        // category: '',
        // name: '',
        // tags: modal.data.tags?.join(','),
      })
      // delete info.view
      // console.log('modal on edit product click', modal.data)
    } else {
      const form = document.getElementById('productMod')
      document.getElementById('productMod').reset()
      console.log('editProduct, form', props.editProduct, form)
      setInfo({})
    }
  }, [props.editProduct, modal])

  // console.log('info', info)

  return (
    <div className={css(styles.container)}>
      {/* <h1 className={css(commonStyles.heading)}>Create Entry</h1> */}
      {props.editProduct ? (
        <h1 className={css(commonStyles.heading)}>Edit Product</h1>
      ) : (
        <h1 className={css(commonStyles.heading)}>Create Product</h1>
      )}
      <form
        className={css(styles.form, commonStyles.paragraph)}
        id="productMod"
        // onSubmit={handleSubmit}
        onSubmit={props.editProduct ? handleEditSubmit : handleSubmit}
      >
        {/* <label className={css(styles.label)}>Product Code</label>
				<input
					className={css(styles.input)}
					value={info.productCode}
					onChange={(e) =>
						setInfo({ ...info, productCode: e.target.value })
					}
				/> */}
        <TextField
          label="Product Code"
          variant="filled"
          fullWidth
          value={info.productCode}
          onChange={(e) => setInfo({ ...info, productCode: e.target.value })}
          disabled={props.editProduct}
          sx={customStyles.textInput}
        />
        {!props.editProduct &&
          props.data.some((item) => item.productCode === info.productCode) && (
            <p
              style={{
                color: 'red',
                margin: 0,
                fontSize: '.7rem',
              }}
            >
              Duplicate productCode
            </p>
          )}
        {/* <p style={{ color: 'red' }} className={css(styles.label)}>
					{props.data.some(
						(item) => item.productCode === info.productCode
					) && 'Duplicate productCode'}
				</p> */}
        {/* <label className={css(styles.label)}>Name</label>
				<input
					className={css(styles.input)}
					onChange={(e) => setInfo({ ...info, name: e.target.value })}
					value={info.name}
				/> */}
        {/* <TextField
					label="Name"
					variant="filled"
					fullWidth
					value={info.name}
					onChange={(e) => setInfo({ ...info, name: e.target.value })}
					sx={customStyles.textInput}
				/> */}
        {/* <label className={css(styles.label)}>
					Tags(comma separeted tags)
				</label>
				<input
					className={css(styles.input)}
					onChange={(e) => setInfo({ ...info, tags: e.target.value })}
					value={info.tags}
				/> */}
        {/* <TextField
					label="Tags"
					variant="filled"
					fullWidth
					value={info.tags}
					onChange={(e) => setInfo({ ...info, tags: e.target.value })}
				/> */}
        {/* <label className={css(styles.label)}>
					Description (optional)
				</label>
				<textarea
					className={css(styles.input)}
					onChange={(e) =>
						setInfo({ ...info, description: e.target.value })
					}
					value={info.description}
				/> */}
        {/* <label className={css(styles.label)}>
					Features (optional) Separete features using //
				</label>
				<textarea
					className={css(styles.input)}
					onChange={(e) =>
						setInfo({ ...info, features: e.target.value })
					}
					value={info.features}
				/> */}
        {/* <label className={css(styles.label)}>
					Collection Name (optional)
				</label>
				<input
					className={css(styles.input)}
					onChange={(e) =>
						setInfo({ ...info, collectionName: e.target.value })
					}
					value={info.collection}
				/> */}

        {/* <label className={css(styles.label)}>Category (optional)</label>
				<input
					className={css(styles.input)}
					onChange={(e) =>
						setInfo({ ...info, category: e.target.value })
					}
					value={info.category}
				/> */}
        {/* <label className={css(styles.label)}>Finish (optional)</label>
				<input
					className={css(styles.input)}
					onChange={(e) =>
						setInfo({ ...info, finish: e.target.value })
					}
					value={info.finish}
				/> */}

        {/* <label className={css(styles.label)}>Glb File</label>
				<input
					type="file"
					className={css(styles.file)}
					value={info.glb}
					onChange={handleGlbChange}
				/> */}
        <Box sx={customStyles.fileUpload}>
          <Button
            sx={customStyles.btn}
            variant="contained"
            component="label"
            size="small"
          >
            Upload glb
            <input
              id="glbFile"
              type="file"
              hidden
              value={info.glb}
              onChange={handleGlbChange}
            />
          </Button>
          <Typography sx={customStyles.filePara}>
            {document.getElementById('glbFile')?.files[0]?.name ??
              'No File Chosen'}
          </Typography>
        </Box>

        {/* <label className={css(styles.label)}>Usdz File</label>
				<input
					type="file"
					className={css(styles.file)}
					value={info.usdz}
					onChange={handleUsdzChange}
				/> */}

        <Box sx={customStyles.fileUpload}>
          <Button
            sx={customStyles.btn}
            variant="contained"
            component="label"
            size="small"
          >
            Upload usdz
            <input
              id="usdzFile"
              type="file"
              hidden
              value={info.usdz}
              onChange={handleUsdzChange}
            />
          </Button>
          <Typography sx={customStyles.filePara}>
            {document.getElementById('usdzFile')?.files[0]?.name ??
              'No File Chosen'}
          </Typography>
        </Box>

        {/* <label className={css(styles.label)}>Thumbnail File</label>
				<input
					type="file"
					className={css(styles.file)}
					value={info.thumbnail}
					onChange={handleImgChange}
				/> */}
        <Box sx={customStyles.fileUpload}>
          <Button
            sx={customStyles.btn}
            variant="contained"
            component="label"
            size="small"
          >
            Upload thumbnail
            <input
              id="thumbnailFile"
              type="file"
              hidden
              value={info.thumbnail}
              onChange={handleImgChange}
            />
          </Button>
          <Typography sx={customStyles.filePara}>
            {document.getElementById('thumbnailFile')?.files[0]?.name ??
              'No File Chosen'}
          </Typography>
        </Box>
        {/* <input
					disabled={!submit}
					className={css(styles.input)}
					type="submit"
				/> */}
        {/* //! Product Dimensions */}
        <h3>Product Dimensions in Centimeters</h3>
        <div style={{ display: 'flex' }}>
          <TextField
            label="X"
            variant="filled"
            // fullWidth
            value={info.dimensionX}
            onChange={(e) => setInfo({ ...info, dimensionX: e.target.value })}
            sx={customStyles.textInput}
          />
          <TextField
            label="Y"
            variant="filled"
            // fullWidth
            value={info.dimensionY}
            onChange={(e) => setInfo({ ...info, dimensionY: e.target.value })}
            sx={customStyles.dimensionInput}
          />
          <TextField
            label="Z"
            variant="filled"
            // fullWidth
            value={info.dimensionZ}
            onChange={(e) => setInfo({ ...info, dimensionZ: e.target.value })}
            sx={customStyles.dimensionInput}
          />
        </div>
        <Button
          disabled={!submit}
          type="submit"
          variant="contained"
          sx={customStyles.submit}
        >
          Submit
        </Button>
      </form>
      <h1>Add Skybox</h1>

      <form style={{ paddingBottom: '10vh' }} onSubmit={handleSkyboxSubmit}>
        {/* <label className={css(styles.label)}>
					Name
					{props.skyboxes.some((x) => x === skyboxInfo.name) && (
						<span style={{ color: 'red' }}>Duplicate Name</span>
					)}
				</label>
				<input
					className={css(styles.input)}
					onChange={(e) =>
						setSkyboxInfo({ ...skyboxInfo, name: e.target.value })
					}
					value={skyboxInfo.name}
				/> */}
        <TextField
          label="Name"
          variant="filled"
          fullWidth
          defaultValue={skyboxInfo.name}
          onChange={(e) =>
            setSkyboxInfo({ ...skyboxInfo, name: e.target.value })
          }
        />
        {props.skyboxes.some((x) => x === skyboxInfo.name) && (
          <span style={{ color: 'red' }}>Duplicate Name</span>
        )}

        {/* <label className={css(styles.label)}>Hdr File</label>
				<input
					type="file"
					className={css(styles.file)}
					value={skyboxInfo.hdr}
					onChange={handleSkyboxChange}
				/> */}
        <Box sx={customStyles.fileUpload}>
          <Button
            variant="contained"
            component="label"
            size="small"
            sx={customStyles.btn}
          >
            Upload hdr
            <input
              id="hdrFile"
              type="file"
              hidden
              value={skyboxInfo.hdr}
              onChange={handleSkyboxChange}
            />
          </Button>
          <Typography sx={customStyles.filePara}>
            {document.getElementById('hdrFile')?.files[0]?.name ??
              'No File Chosen'}
          </Typography>
        </Box>
        {/* <input
					disabled={
						!(
							skyboxInfo.name &&
							skyboxInfo.hdr &&
							!props.skyboxes.some((x) => x === skyboxInfo.name)
						)
					}
					className={css(styles.input)}
					value="Add skybox"
					type="submit"
				/> */}
        <Button
          disabled={
            !(
              skyboxInfo.name &&
              skyboxInfo.hdr &&
              !props.skyboxes.some((x) => x === skyboxInfo.name)
            )
          }
          type="submit"
          variant="contained"
          sx={customStyles.submit}
        >
          ADD SKYBOX
        </Button>
      </form>
    </div>
  )
}

export default Create

const styles = StyleSheet.create({
  container: {
    // position: 'absolute',
    width: '100%',
    height: '100%',
    '@media (max-width: 900px)': {
      display: 'block',
      marginTop: '200px',
      marginLeft: '20%',
    },
    overflowY: 'scroll',
  },
  form: {
    display: 'block',
    width: '100%',
  },
  label: {
    display: 'block',
    fontSize: '0.7rem',
    marginTop: '1%',
  },
  input: {
    display: 'block',
    fontSize: '1rem',
    width: '30%',
  },
})

const customStyles = {
  fileUpload: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    margin: '1rem 0',
  },
  filePara: {
    fontSize: '.9rem',
  },
  textInput: {
    marginBottom: '1rem',
  },
  dimensionInput: {
    marginLeft: '1rem',
  },
  btn: {
    textTransform: 'none',
  },
  submit: {
    background: '#000',
    ':hover': {
      background: 'rgba(40, 40, 40, 1)',
    },
  },
}
