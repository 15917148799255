import { useEffect } from 'react'

function Orientation({ orientation, setOrientation }) {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)')

    const handleOrientationChange = (event) => {
      setOrientation(event.matches ? 'portrait' : 'landscape')
    }

    mediaQuery.addEventListener('change', handleOrientationChange)
    handleOrientationChange(mediaQuery)

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange)
    }
  }, [orientation])

  return null
}

export default Orientation
