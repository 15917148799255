import React, { Suspense, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { baseUrl, useModal } from '../store'
import commonStyles from './commonStyles'
import axios from 'axios'
import AR from './AR'
import CMSModelViewer from './CMSModelViewer'
import { Button, Container } from '@mui/material'

const Modal = (props) => {
  const modal = useModal()
  const [info, setInfo] = useState({})
  const ref = useRef()
  const [view, setView] = useState({})
  const [landscapeView, setLandscapeView] = useState({})
  const [portraitView, setPortraitView] = useState({})
  const [contactShadow, setContactShadow] = useState({})
  const [actualGlb, setActualGlb] = useState()
  const [actualUsdz, setActualUsdz] = useState()
  const [loadingState, setLoading] = useState(0)
  const [actualImg, setImg] = useState()
  const [submit, setSubmit] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)
  const [dayNightHDRI, setDayNightHDRI] = useState({})
  const [offsetY, setOffsetY] = useState(-15)
  const [minZoom, setMinZoom] = useState(20)
  const [maxZoom, setMaxZoom] = useState(100)
  const [lookAtX, setLookAtX] = useState(0)
  const [lookAtY, setLookAtY] = useState(0)
  const [lookAtZ, setLookAtZ] = useState(0)

  const [dimensionPositionX, setDimensionPositionX] = useState(0)
  const [dimensionPositionY, setDimensionPositionY] = useState(0)
  const [dimensionPositionZ, setDimensionPositionZ] = useState(0)

  //! Mode
  const modeValues = ['Desktop', 'Portrait', 'Landscape']
  const [mode, setMode] = useState('Desktop')

  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value })
    setActualGlb(e.target.files[0])
  }

  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value })
    setActualUsdz(e.target.files[0])
  }

  const handleSubmitView = async (e) => {
    const formData = new FormData()
    formData.append('view', JSON.stringify(view))
    console.log(view)
    props.setLoading(1)
    try {
      let res = await axios.put(
        `${baseUrl}/product/edit-view/${modal.data._id}`,
        formData
      )
      props.setLoading(2)
      console.log('res', res)
    } catch (err) {
      props.setLoading(-1)
      console.log('err', err)
    }
    props.toggleChanged()
  }

  const handleSaveSettings = async (e) => {
    const formData = new FormData()
    if (mode === 'Desktop') {
      formData.append('view', JSON.stringify(view))
    } else if (mode === 'Landscape') {
      formData.append('landscapeView', JSON.stringify(landscapeView))
    } else if (mode === 'Portrait') {
      formData.append('portraitView', JSON.stringify(portraitView))
    }

    // formData.append('view', JSON.stringify(''))
    formData.append('contactShadow', JSON.stringify(contactShadow))

    formData.append('dayHDRI', dayNightHDRI['dayHDRI'])
    formData.append('nightHDRI', dayNightHDRI['nightHDRI'])
    formData.append('offsetY', offsetY)
    formData.append('minZoom', minZoom)
    formData.append('maxZoom', maxZoom)
    formData.append('lookAtX', lookAtX)
    formData.append('lookAtY', lookAtY)
    formData.append('lookAtZ', lookAtZ)

    formData.append('dimensionPositionX', dimensionPositionX)
    formData.append('dimensionPositionY', dimensionPositionY)
    formData.append('dimensionPositionZ', dimensionPositionZ)

    console.log(
      `posX: ${dimensionPositionX}, posY: ${dimensionPositionY}, posZ: ${dimensionPositionZ}`
    )

    props.setLoading(1)
    try {
      let res = await axios.put(
        `${baseUrl}/product/edit/${modal.data._id}`,
        formData
      )
      props.setLoading(2)
      console.log('res', res)
    } catch (err) {
      props.setLoading(-1)
      console.log('err', err)
    }
    props.toggleChanged()
  }

  const handleSubmitContactShadow = async (e) => {
    const formData = new FormData()
    formData.append('contactShadow', JSON.stringify(contactShadow))
    props.setLoading(1)

    try {
      let res = await axios.put(
        `${baseUrl}/product/edit-contact-shadow/${modal.data._id}`,
        formData
      )
      props.setLoading(2)
      // console.log('res', res)
    } catch (err) {
      props.setLoading(-1)
      console.log('err', err)
    }
    props.toggleChanged()
  }

  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value })
    setImg(e.target.files[0])
  }
  const handleFullscreen = (shouldMaximize) => {
    if (shouldMaximize) {
      setFullscreen(true)
    } else setFullscreen(false)
  }

  useEffect(() => {}, [modal.show])

  useEffect(() => {
    setInfo({
      features: '',
      collectionName: '',
      finish: '',
      tags: '',
      description: '',
      category: '',
      name: '',
      ...modal.data,
      glb: '',
      tags: modal.data.tags?.join(','),
      usdz: '',
      thumbnail: '',
      // dimensionX: '',
      // dimensionY: '',
      // dimensionZ: '',
      offsetY: '',
      minZoom: '',
      maxZoom: '',
      lookAtX: '',
      lookAtY: '',
      lookAtZ: '',
      dimensionPositionX: '',
      dimensionPositionY: '',
      dimensionPositionZ: '',
      id: modal.data.productCode,
    })
    delete info.view
    delete info.landscapeView

    modal.data.view ? setView(modal.data.view) : setView({})
    // console.log('modal : ', modal.data)
    modal.data.landscapeView
      ? setLandscapeView(modal.data.landscapeView)
      : modal.data.view
      ? setLandscapeView(modal.data.view)
      : setLandscapeView({})
    modal.data.portraitView
      ? setPortraitView(modal.data.portraitView)
      : setPortraitView(modal.data.view)
    modal.data.contactShadow
      ? setContactShadow(modal.data.contactShadow)
      : setContactShadow({})
    modal.data.offsetY ? setOffsetY(modal.data.offsetY) : setOffsetY(0)
    modal.data.minZoom ? setMinZoom(modal.data.minZoom) : setMinZoom(20)
    modal.data.maxZoom ? setMaxZoom(modal.data.maxZoom) : setMaxZoom(100)
    modal.data.lookAtX ? setLookAtX(modal.data.lookAtX) : setLookAtX(0)
    modal.data.lookAtY ? setLookAtY(modal.data.lookAtY) : setLookAtY(0)
    modal.data.lookAtZ ? setLookAtZ(modal.data.lookAtZ) : setLookAtZ(0)

    modal.data.dimensionPositionX
      ? setDimensionPositionX(modal.data.dimensionPositionX)
      : setDimensionPositionX(0)
    modal.data.dimensionPositionY
      ? setDimensionPositionY(modal.data.dimensionPositionY)
      : setDimensionPositionY(0)
    modal.data.dimensionPositionZ
      ? setDimensionPositionZ(modal.data.dimensionPositionZ)
      : setDimensionPositionZ(0)

    if (modal.data) {
      // console.log('modal data', modal.data)
      // if (modal.data.dayHDRI && modal.data.nightHDRI) {
      const customDayNightHDRI = {
        dayHDRI: modal.data.dayHDRI,
        nightHDRI: modal.data.nightHDRI,
      }
      // console.log('customDayNightHDRI', customDayNightHDRI)
      setDayNightHDRI({ ...customDayNightHDRI })
      // }
      // else {
      //   const customDayNightHDRI = {
      //     dayHDRI: modal.data.skyboxes[0],
      //     nightHDRI: modal.data.skyboxes[0],
      //   }
      //   // console.log('customDayNightHDRI', customDayNightHDRI)
      //   setDayNightHDRI({ ...customDayNightHDRI })
      // }
    }

    // console.log('modal', modal.data)
  }, [modal.data])

  const handleDelete = async () => {
    try {
      let res = await axios.delete(
        `${baseUrl}/product/?id=${modal.data._id}&modelId=${modal.data.productCode}`
      )
      modal.setShow(false)
      props.toggleChanged()
    } catch (err) {
      console.log(err)
    }
    modal.setShow(false)
    props.toggleChanged()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('sending packet')
    props.setLoading(1)
    if (actualGlb) info['glb'] = actualGlb
    else delete info.glb
    if (actualUsdz) info.usdz = actualUsdz
    else delete info.usdz
    if (actualImg) info.thumbnail = actualImg
    else delete info.thumbnail
    let formData = new FormData()

    for (let i in info) {
      // console.log('key i in info', i, info[i])
      if (i === 'view' || i === 'contactShadow') {
        formData.append(i, JSON.stringify(info[i]))
        continue
      }
      if (i === 'tags') console.log(i, info[i], info[i].split(/[\s,]+/))
      if (i === 'thumbnail') formData.append(i, actualImg)
      else if (i === 'tags') formData.append(i, info[i].split(/[\s,]+/))
      else formData.append(i, info[i])
    }

    info.glb = ''
    info.usdz = ''
    info.thumbnail = ''

    setLoading(1)
    // console.log(
    // 	'-----------------------output formData-----------------------'
    // )
    // for (let val of formData.entries()) {
    // 	console.log(val[0] + ', ' + val[1])
    // }
    try {
      let res = await axios.put(
        `${baseUrl}/product/edit/${modal.data._id}`,
        formData
      )
      props.setLoading(2)
      console.log('res', res)
    } catch (err) {
      props.setLoading(-1)
      console.log('err', err)
    }

    props.toggleChanged()

    props.toggleChanged()
  }

  return (
    modal.show && (
      <>
        <div
          onClick={() => modal.setShow(false)}
          className={css(styles.backdrop)}
        ></div>
        <div className={css(styles.container)}>
          {/* <form
						className={css(styles.form, commonStyles.paragraph)}
						onSubmit={handleSubmit}
					>
						<label className={css(styles.label)}>Product Id</label>
						<input
							disabled={true}
							className={css(styles.input)}
							value={info.id}
							onChange={(e) =>
								setInfo({ ...info, id: e.target.value })
							}
						/>
						<label className={css(styles.label)}>Name</label>
						<input
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({ ...info, name: e.target.value })
							}
							value={info.name}
						/>
						<label className={css(styles.label)}>Description</label>
						<textarea
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({
									...info,
									description: e.target.value,
								})
							}
							value={info.description}
						/>

						<label className={css(styles.label)}>
							Tags(comma separeted tags)
						</label>
						<input
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({ ...info, tags: e.target.value })
							}
							value={info.tags}
						/>
						<label className={css(styles.label)}>
							Category (optional)
						</label>
						<input
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({ ...info, category: e.target.value })
							}
							value={info.category}
						/>
						<label className={css(styles.label)}>
							Features (optional) Separete features using //
						</label>
						<textarea
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({ ...info, features: e.target.value })
							}
							value={info.features}
						/>
						<label className={css(styles.label)}>
							Collection Name (optional)
						</label>
						<input
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({
									...info,
									collectionName: e.target.value,
								})
							}
							value={info.collectionName}
						/>
						<label className={css(styles.label)}>
							Finish (optional)
						</label>
						<input
							className={css(styles.input)}
							onChange={(e) =>
								setInfo({ ...info, finish: e.target.value })
							}
							value={info.finish}
						/>
						<label className={css(styles.label)}>File</label>
						<input
							type="file"
							className={css(styles.file)}
							value={info.glb}
							onChange={handleGlbChange}
						/>
						<label className={css(styles.label)}>Usdz File</label>
						<input
							type="file"
							className={css(styles.file)}
							value={info.usdz}
							onChange={handleUsdzChange}
						/>
						<label className={css(styles.label)}>
							Thumbnail File
						</label>
						<input
							type="file"
							className={css(styles.file)}
							value={info.thumbnail}
							onChange={handleImgChange}
						/>
						<input
							disabled={!submit}
							value={'Edit'}
							className={css(styles.input)}
							type="submit"
						/>
					</form> */}

          {/* <button
						style={{
							position: 'absolute',
							right: 0,
							bottom: '10vh',
							zIndex: 90,
						}}
						onClick={handleDelete}
					>
						delete product
					</button> */}
          {/* <Button
						variant="contained"
						sx={{
							position: 'absolute',
							right: 0,
							bottom: '10vh',
							zIndex: 90,
						}}
					>
						Custom
					</Button> */}
          <div className={css(styles.model)}>
            <div
              style={{
                position: 'absolute',
                left: '1vh',
                top: '1vh',
                zIndex: 10,
              }}
            >
              <AR
                buttonStyle={css(styles.button, styles.customButton)}
                svgStyle={css(styles.svgStyle)}
                appleUrl={
                  baseUrl +
                  `/threed-model/usdz/${modal.data.productCode}/model.usdz`
                }
                androidURL={
                  baseUrl +
                  `/threed-model/model/${modal.data.productCode}/model.glb`
                }
              />
            </div>
            <CMSModelViewer
              view={view}
              setView={setView}
              landscapeView={landscapeView}
              setLandscapeView={setLandscapeView}
              portraitView={portraitView}
              setPortraitView={setPortraitView}
              skyboxes={props.skyboxes}
              contactShadow={contactShadow}
              setContactShadow={setContactShadow}
              setDayNightHDRI={setDayNightHDRI}
              dayNightHDRI={dayNightHDRI}
              offsetY={offsetY}
              setOffsetY={setOffsetY}
              minZoom={minZoom}
              setMinZoom={setMinZoom}
              maxZoom={maxZoom}
              setMaxZoom={setMaxZoom}
              lookAtX={lookAtX}
              lookAtY={lookAtY}
              lookAtZ={lookAtZ}
              setLookAtX={setLookAtX}
              setLookAtY={setLookAtY}
              setLookAtZ={setLookAtZ}
              modeValues={modeValues}
              setMode={setMode}
              dimensionPositionX={dimensionPositionX}
              dimensionPositionY={dimensionPositionY}
              dimensionPositionZ={dimensionPositionZ}
              setDimensionPositionX={setDimensionPositionX}
              setDimensionPositionY={setDimensionPositionY}
              setDimensionPositionZ={setDimensionPositionZ}
            />
            <Container sx={customStyles.btnsContainer}>
              <Button
                variant="contained"
                href={`/product/${modal.data.productCode}`}
                target="_blank"
              >
                Preview Model
              </Button>
              <Button variant="contained" onClick={handleSaveSettings}>
                Save Settings
              </Button>
              {/* <Button
								variant="contained"
								onClick={handleSubmitView}
							>
								Set Camera Angle
							</Button> */}

              {/* <Button
								variant="contained"
								onClick={handleSubmitContactShadow}
							>
								Set Contact Shadow
							</Button> */}
              <Button variant="contained" onClick={handleDelete}>
                Delete Product
              </Button>
            </Container>
          </div>
        </div>
      </>
    )
  )
}

export default Modal

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  container: {
    position: 'absolute',
    zIndex: 10,
    width: '80%',
    height: '80%',
    top: '50%',
    left: '50%',
    display: 'flex',
    backgroundColor: 'white',
    transform: 'translate(-50%,-50%)',
    '@media (max-width: 900px)': {
      width: '100%',
      left: 0,
      transform: 'translate(0,-50%)',
    },
  },
  textContainer: {
    width: '50%',
    height: '100%',
    overflow: 'scroll',
  },
  model: {
    width: '100%',
    height: '85%',
    border: '1px white solid',
  },
  button: {
    // fontSize: 30,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    marginLeft: '0.2em',
    // flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 0,
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.9rem',
      // height: '2em',
      // width: '2em',
      marginLeft: 0,
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        // fontSize: 25,
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        // fontSize: 30,
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        // fontSize: 25,
        marginLeft: 0,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 30,
        marginLeft: 0,
      },
    '@media(min-width: 1200px)': {
      // fontSize: 30,
      marginLeft: 0,
    },
    '@media(min-width: 1920px)': {
      // fontSize: '3em',
      marginLeft: 0,
    },
  },
  customButton: {
    paddingLeft: 0,
  },
  customButtonStyle: {
    '@media(max-width: 576px) and (orientation:portrait)': {
      marginLeft: 0,
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        marginLeft: '0.8em',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        marginLeft: 0,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        marginLeft: '0.3em',
      },
    '@media(min-width: 1200px)': {
      // marginLeft: '0.1em',
      marginLeft: '0.3em',
    },
    '@media(min-width: 1920px)': {
      // fontSize: '3em',
      marginLeft: '0.5em',
    },
    '@media(min-width: 2560px)': {
      // fontSize: '3em',
      marginLeft: '0.8em',
    },
  },
  svgStyle: {
    width: '1.5em',
    height: '1.5em',
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.9rem',
      height: 'auto',
      width: '6.2vh',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '5.9vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '4.4vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        height: 'auto',
        width: '7.5vw',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 25,
        height: 'auto',
        width: '3.9vw',
        marginLeft: '0%',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      // fontSize: 25,
      height: 'auto',
      width: '3.9vw',
      marginLeft: '0%',
    },
    '@media(min-width: 1400px)': {
      // fontSize: 25,
      height: 'auto',
      width: '3.3vw',
      marginLeft: '0%',
    },
  },
  form: {
    display: 'block',
    width: '100%',
    margin: '1%',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  label: {
    display: 'block',
    fontSize: '0.7rem',
    marginTop: '1%',
  },
  input: {
    display: 'block',
    fontSize: '1rem',
    width: '90%',
  },
  fullscreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 30,
  },
  viewbutton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  contactShadowButton: {
    position: 'absolute',
    right: 150,
    bottom: 0,
  },
  buttonFullscreen: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

const customStyles = {
  btnsContainer: {
    display: 'flex',
    justifyContent: 'right',
    gap: 2,
    // padding: '10px 10px',
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 600px)': {
      padding: '20px 10px',
    },
  },
}
