import { useSpring } from '@react-spring/web'
import { css, StyleSheet } from 'aphrodite'
import { useQR } from '../store'
import QRCode from 'react-qr-code'

const QRModal = ({ setDimensionStatus, dimensionStatus, setDimension }) => {
  const qr = useQR()
  const spring = useSpring({
    opacity: qr.url ? 1 : 0,
  })

  function onBackDropClick() {
    if (dimensionStatus === 'closed') {
      setDimensionStatus('open')
      setDimension(true)
    }
    qr.setQR('')
  }

  return (
    <div
      style={{ pointerEvents: qr.url ? 'auto' : 'none' }}
      className={css(styles.container)}
    >
      <div
        className={css(styles.backdrop)}
        onClick={() => onBackDropClick()}
      ></div>
      <div className={css(styles.smallContainer)}>
        <h1 className={css(styles.scanMe)}>Scan me for viewing in your room</h1>
        <QRCode
          fgColor="black"
          bgColor="white"
          width="100%"
          height="100%"
          value={qr.url}
        />
        <h1 className={css(styles.close)}>Tap outside to close</h1>
      </div>
    </div>
  )
}
export default QRModal

const styles = StyleSheet.create({
  smallContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    backgroundColor: 'grey',
    padding: '1vh 2vh 1vh 2vh',
    borderRadius: '2vh',
    transform: 'scale(1.3) translate(-50%,-45%)',
    textAlign: 'center',
  },
  scanMe: {
    color: 'white',
    fontSize: '2.2vh',
  },
  qr: {
    top: '50%',
    left: '50%',
    backgroundColor: 'red',
    position: 'absolute',
    zIndex: 1000,
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
  },
  close: {
    textAlign: 'right',
    fontSize: '1vh',
    color: 'white',
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: '100vw',
    height: '100vh',
  },
})
