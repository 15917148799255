import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Measure from '../svgs/Measure'
import { isMobile } from 'react-device-detect'
import ARView from '../svgs/ARView'
import Lottie from 'lottie-react'
import { useSpring } from '@react-spring/web'
import { a as animated } from '@react-spring/web'
import AR from './AR'
import MeasureClose from '../svgs/MeasureClose'
import { useHud } from '../store'
import { sendEvent } from '../helpers/google-analytics'

function HUD({
  isDark,
  appleUrl,
  androidURL,
  dimension,
  setDimension,
  setDimensionStatus,
  setIsDark,
  showHud,
}) {
  const defaultRenderSettings = {
    filterSize: {
      width: '200%',
      height: '200%',
      x: '-50%',
      y: '-50%',
    },
  }
  const themeAnimation = require('../lotties/themeAnimation.json')
  const lottieRef = useRef()

  // const [dimension, setDimension] = useState(false)
  // const [showHud, setShowHud] = useState(true)
  // const [dimensionStatus, setDimensionStatus] = useState('open')
  const ModalAnimation = useHud()
  const [tooltipHover, setTooltipHover] = useState(null)

  const HudFadeAnim = useSpring({
    opacity: showHud ? 1 : 0,
    background: `${isDark ? 'rgba(255,255, 255,0.4)' : 'rgba(0,0, 0,0.2)'}`,
    // background: `${isDark ? '#EBEBEB' : '#EBEBEB'}`,
    config: { duration: 200 },
  })

  const MeasureTooltipHover = useSpring({
    opacity: tooltipHover === 'MeasureTooltip' ? 1 : 0,
    config: { duration: isMobile ? 500 : 200 },
  })

  const ToggleTooltipHover = useSpring({
    opacity: tooltipHover === 'ToggleTooltip' ? 1 : 0,
    config: { duration: isMobile ? 500 : 200 },
  })

  const ARTooltipHover = useSpring({
    opacity: tooltipHover === 'ARTooltip' ? 1 : 0,
    config: { duration: isMobile ? 500 : 200 },
  })

  useEffect(() => {
    if (isMobile && showHud) {
      if (tooltipHover === null) {
        setTimeout(() => {
          setTooltipHover('ARTooltip')
        }, 5000)
      } else if (tooltipHover === 'ARTooltip') {
        setTimeout(() => {
          setTooltipHover('MeasureTooltip')
        }, 5000)
      } else if (tooltipHover === 'MeasureTooltip') {
        setTimeout(() => {
          setTooltipHover('ToggleTooltip')
        }, 5000)
      } else if (tooltipHover === 'ToggleTooltip') {
        setTimeout(() => {
          setTooltipHover('CloseAll')
        }, 5000)
      }
    }
  }, [tooltipHover, showHud])

  useEffect(() => {
    if (lottieRef.current != null) {
      lottieRef.current.setDirection(isDark ? 1 : -1)
      lottieRef.current.play()
    }

    // console.log('lottieRef', lottieRef)
  }, [isDark])

  return (
    <animated.div className={css(styles.container)} style={HudFadeAnim}>
      <AR
        buttonStyle={css(styles.button, styles.customButton)}
        svgStyle={css(styles.svgStyle)}
        isDark={isDark}
        appleUrl={appleUrl}
        androidURL={androidURL}
        setDimension={setDimension}
        dimension={dimension}
        setDimensionStatus={setDimensionStatus}
        ARTooltipHover={ARTooltipHover}
        setTooltipHover={setTooltipHover}
        showHud={showHud}
        ModalAnimation={ModalAnimation.isPlaying}
      />
      <button
        className={css(styles.button, styles.customButtonStyle)}
        onClick={() => {
          setDimension(!dimension)
          sendEvent(
            `dimensions ${!dimension ? 'open' : 'close'}`,
            'UI',
            'Measurement button'
          )
        }}
        onPointerEnter={() => !isMobile && setTooltipHover('MeasureTooltip')}
        onPointerLeave={() => !isMobile && setTooltipHover(null)}
        style={{
          pointerEvents: ModalAnimation.isPlaying || !showHud ? 'none' : 'auto',
        }}
      >
        {dimension ? (
          <MeasureClose svgStyle={css(styles.customStyle)} />
        ) : (
          <Measure svgStyle={css(styles.customStyle)} />
        )}
      </button>
      {/* //* Tooltip */}
      <animated.div
        className={css(styles.measureTooltipContainer)}
        style={MeasureTooltipHover}
      >
        <div
          className={css(styles.measureTooltipTrinangle, styles.customTriangle)}
        ></div>
        <div className={css(styles.skyboxToolTip, styles.customTooltip)}>
          Tap to see the product's measurements
        </div>
      </animated.div>

      <button
        className={css(styles.button, styles.lottieStyle)}
        onClick={() => {
          setIsDark(!isDark)
          sendEvent(
            `${isDark ? 'Night' : 'Day'} Mode`,
            'UI',
            'Day/Night Toggle'
          )
        }}
        onPointerEnter={() => !isMobile && setTooltipHover('ToggleTooltip')}
        onPointerLeave={() => !isMobile && setTooltipHover(null)}
        style={{
          pointerEvents: !showHud ? 'none' : 'auto',
        }}
      >
        <Lottie
          animationData={themeAnimation}
          loop={false}
          autoplay={false}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          lottieRef={lottieRef}
          rendererSettings={defaultRenderSettings}
        />
        <animated.div
          className={css(styles.toolTipContainer)}
          style={ToggleTooltipHover}
        >
          <div className={css(styles.measureTooltipTrinangle)}></div>
          <div className={css(styles.skyboxToolTip)}>Toggle day/night</div>
        </animated.div>
      </button>
    </animated.div>
  )
}

export default HUD

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '2vh',
    // backgroundColor: 'rgba(0,0,0,0.3)',
    // paddingLeft: '1.2em',
    // paddingRight: '0.55em',
    // paddingTop: '0.5em',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '1em',
    borderRadius: '0.5em',
    padding: '0.3em',
    '@media(max-width: 576px) and (orientation:portrait)': {
      padding: '0.8em',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        padding: '0.8em',
        left: '2%',
        transform: 'translateX(0%)',
        borderRadius: '0.3em',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        padding: '0.8em',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        padding: '0.5em',
        left: '2%',
        transform: 'translateX(0%)',
        borderRadius: '0.3em',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        padding: '0.6em',
        left: '2%',
        transform: 'translateX(0%)',
        borderRadius: '0.3em',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      padding: '0.6em',
      left: '1%',
      transform: 'translateX(0%)',
      borderRadius: '0.4em',
    },
    '@media(min-width: 1400px)': {
      padding: '0.6em',
      left: 'unset',
      right: '5%',
      transform: 'translateX(0%)',
      bottom: 'unset',
      top: '5%',
    },
    '@media(min-width: 1920px)': {
      padding: '0.8em',
    },
    '@media(min-width: 2560px)': {
      padding: '1.2em',
      borderRadius: '0.8em',
    },
  },
  button: {
    // fontSize: 30,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    marginLeft: '0.2em',
    // flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 0,
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.9rem',
      // height: '2em',
      // width: '2em',
      marginLeft: 0,
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        // fontSize: 25,
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        // fontSize: 30,
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        // fontSize: 25,
        marginLeft: 0,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 30,
        marginLeft: 0,
      },
    '@media(min-width: 1200px)': {
      // fontSize: 30,
      marginLeft: 0,
    },
    '@media(min-width: 1920px)': {
      // fontSize: '3em',
      marginLeft: 0,
    },
  },
  customButton: {
    paddingLeft: 0,
  },
  customButtonStyle: {
    '@media(max-width: 576px) and (orientation:portrait)': {
      marginLeft: 0,
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        marginLeft: 0,
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        marginLeft: '0.8em',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        marginLeft: 0,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        marginLeft: '0.3em',
      },
    '@media(min-width: 1200px)': {
      // marginLeft: '0.1em',
      marginLeft: '0.3em',
    },
    '@media(min-width: 1920px)': {
      // fontSize: '3em',
      marginLeft: '0.5em',
    },
    '@media(min-width: 2560px)': {
      // fontSize: '3em',
      marginLeft: '0.8em',
    },
  },
  svgStyle: {
    width: '1.5em',
    height: '1.5em',
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.9rem',
      height: 'auto',
      width: '6.2vh',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '5.9vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '4.4vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        height: 'auto',
        width: '7.5vw',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 25,
        height: 'auto',
        width: '3.9vw',
        marginLeft: '0%',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      // fontSize: 25,
      height: 'auto',
      width: '3.9vw',
      marginLeft: '0%',
    },
    '@media(min-width: 1400px)': {
      // fontSize: 25,
      height: 'auto',
      width: '3.3vw',
      marginLeft: '0%',
    },
  },
  lottieStyle: {
    width: '4em',
    // height: '4em',
    marginLeft: '-4%',
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.58rem',
      width: '13vh',
      marginLeft: '0%',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '12vw',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '9vw',
        marginLeft: '0%',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        height: 'auto',
        width: '15vw',
        marginLeft: '0.8em',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 25,
        height: 'auto',
        width: '8vw',
        marginLeft: '0.35em',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      // fontSize: 25,
      height: 'auto',
      width: '8vw',
      marginLeft: '0.3em',
    },
    '@media(min-width: 1400px)': {
      // fontSize: 25,
      height: 'auto',
      width: '7vw',
      marginLeft: '0.3em',
    },
    '@media(min-width: 1920px)': {
      // fontSize: '2.28em',
      marginLeft: '0.5em',
    },
    '@media(min-width: 2560px)': {
      // fontSize: '3em',
      marginLeft: '0.8em',
    },
  },
  customStyle: {
    width: '1.6em',
    height: '1.6em',
    '@media(max-width: 576px) and (orientation:portrait)': {
      // fontSize: '1.9rem',
      height: 'auto',
      width: '6.5vh',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '6vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:landscape)':
      {
        height: 'auto',
        width: '4.5vw',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        height: 'auto',
        width: '7.5vw',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        // fontSize: 25,
        height: 'auto',
        width: '4vw',
        marginLeft: '0%',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      // fontSize: 25,
      height: 'auto',
      width: '4vw',
      marginLeft: '0%',
    },
    '@media(min-width: 1400px)': {
      // fontSize: 25,
      height: 'auto',
      width: '3.5vw',
      marginLeft: '0%',
    },
  },
  measureTooltipContainer: {
    position: 'absolute',
    top: '-25%',
    '@media(max-width: 576px) and (orientation:portrait)': {
      top: '-75%',
      marginLeft: '25%',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
        // left: '25%',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        top: '-60%',
        marginLeft: '25%',
        // left: '25%',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      top: '-60%',
      marginLeft: '25%',
    },
    '@media(min-width: 1200px)': {
      top: '4.2em',
      // top: 'unset',
      marginLeft: '25%',
    },
    '@media(min-width: 1920px)': {
      top: '100%',
      marginLeft: '25%',
    },
  },
  customTriangle: {
    '@media(max-width: 576px) and (orientation:portrait)': {
      marginTop: 38,
    },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        marginTop: 29,
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      marginTop: 29,
    },
    '@media(min-width: 1400px)': {
      marginTop: -12,
    },
  },
  measureTooltipTrinangle: {
    width: 0,
    height: 0,
    borderLeft: '.6rem solid transparent',
    borderRight: '.6rem solid transparent',
    // borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
    marginLeft: '2vh',

    position: 'absolute',
    marginTop: 25,
    borderTop: '.8rem solid rgba(0, 0, 0, .7)',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        marginTop: 33.5,
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        marginTop: 29,
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      marginTop: 29,
    },
    '@media(min-width: 1400px)': {
      borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
      borderTop: 'unset',
      top: 0,
      marginTop: -12,
    },
  },
  skyboxToolTip: {
    display: 'block',
    whiteSpace: 'nowrap',
    // width: '7rem',
    background: 'rgba(0, 0, 0, .7)',
    // borderRadius: 5,
    textAlign: 'center',
    padding: '0.35rem 0.9rem',
    fontSize: '0.75rem',
    color: '#fff',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        fontSize: '1.25rem',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        fontSize: '1rem',
      },
    '@media(min-width: 1400px)': {
      fontSize: '0.8vw',
    },
  },
  customTooltip: {
    '@media(max-width: 576px) and (orientation:portrait)': {
      width: '12em',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
    },
  },
  toolTipContainer: {
    position: 'absolute',
    // right: 0,
    // marginTop: -10,
    top: '-25%',
    marginLeft: '8%',
    '@media(max-width: 576px) and (orientation:portrait)': {
      top: '-60%',
      marginLeft: '10%',
    },
    '@media(min-width: 577px) and (max-width: 767px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 768px) and (max-width: 992px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 993px) and (max-width: 1199px) and (orientation:landscape)':
      {
        top: '-60%',
        marginLeft: '25%',
      },
    '@media(min-width: 1200px) and (orientation:landscape)': {
      top: '-60%',
      marginLeft: '25%',
    },
    '@media(min-width: 1400px)': {
      top: '100%',
      marginLeft: '25%',
    },
    '@media(min-width: 1920px)': {
      top: '100%',
      marginLeft: '0%',
    },
  },
})
