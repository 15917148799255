import { css, StyleSheet } from 'aphrodite'

const CMSLoading = ({ loading }) => {
	return (
		<div className={css(styles.container)}>
			{loading === 2 ? (
				<p style={{ color: 'green' }}>Successful...</p>
			) : loading === 1 ? (
				<p style={{ color: 'yellow' }}>Waiting for response...</p>
			) : loading === -1 ? (
				<p style={{ color: 'red' }}>Could not get response...</p>
			) : null}
		</div>
	)
}

export default CMSLoading

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		width: '100vw',
		height: '10%',
		overflow: 'hidden',
		zIndex: 120,
		top: 0,
		left: 0,
		textAlign: 'center',
		pointerEvents: 'none',
	},
})
