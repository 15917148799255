import { BBAnchor, Html, Line } from '@react-three/drei'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'

function ModelDimensions({
  minX,
  maxX,
  minY,
  maxY,
  minZ,
  maxZ,
  modelRef,
  isDark,
  modalData,
}) {
  return (
    <>
      {/* //? X-axis */}
      <BBAnchor anchor={[0, 0, 1.09 + modalData.dimensionPositionX]}>
        <Line
          points={[
            [maxX / 1.2, 0, 0],
            [minX / 1.2, 0, 0],
          ]}
          color={isDark ? 'white' : 'black'}
          lineWidth={2}
        />

        <Html
          style={{ pointerEvents: 'none' }}
          occlude={[modelRef]}
          position={[(maxX + minX) / 2, 0, 0]}
          center
        >
          <div
            className={css(styles.text)}
            style={{ padding: `${(maxX - minX) * 0.2}px` }}
          >
            {modalData.dimensionX != null ? modalData.dimensionX : 20}cm
          </div>
        </Html>
      </BBAnchor>

      {/* //? Y-axis */}
      <BBAnchor anchor={[-1.09 + modalData.dimensionPositionY, 0, -1]}>
        <Line
          points={[
            [0, minY, 0],
            [0, minY + (maxY - minY) / 1.2, 0],
          ]}
          position={[0, (maxY - minY) / 10, 0]}
          color={isDark ? 'white' : 'black'} // set the color to blue
          lineWidth={2}
        />
        <Html
          occlude={[modelRef]}
          style={{ pointerEvents: 'none' }}
          position={[0, (minY + (maxY - minY)) / 2, 0]}
          center
        >
          <div
            className={css(styles.text)}
            style={{ padding: `${(maxX - minX) * 0.2}px` }}
          >
            {modalData.dimensionY != null ? modalData.dimensionY : 20}cm
          </div>
        </Html>
      </BBAnchor>

      {/* //? Z-axis */}
      <BBAnchor anchor={[-1.09 + modalData.dimensionPositionZ, 0, 0]}>
        <Line
          points={[
            [0, 0, minZ],
            [0, 0, minZ + (maxZ - minZ) / 1.2],
          ]} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
          color={isDark ? 'white' : 'black'} // set the color to blue
          lineWidth={2} // In pixels (default)
          position={[0, 0, (maxZ - minZ) / 10]}
        />
        <Html
          occlude={[modelRef]}
          style={{ pointerEvents: 'none' }}
          position={[0, 0, (maxZ + minZ) / 2]}
          center
        >
          <div
            className={css(styles.text)}
            style={{ padding: `${(maxZ - minZ) * 0.2}px` }}
          >
            {modalData.dimensionZ != null ? modalData.dimensionZ : 20}cm
          </div>
        </Html>
      </BBAnchor>
    </>
  )
}

export default ModelDimensions

const styles = StyleSheet.create({
  text: {
    // padding: '1.5vh 3vh',
    background: 'rgba(255,255,255,0.8)',
    color: 'rgba(0,0,0)',
    borderRadius: '0.8vh',
    // fontWeight: 'bold',
    border: '0.2vh solid rgba(0,0,0, 0.9)',
    userSelect: 'none',
  },
})
