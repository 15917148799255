import {
  ContactShadows,
  Environment,
  Html,
  OrbitControls,
  useProgress,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'
import { baseUrl, useHud, useQR } from '../store'
import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import Tap from '../svgs/Tap'
import Camera from './Camera'
import QRModal from '../components/QRModal'
import ProductModel from './ProductModel'
import Background from './Background'
import TapDark from '../svgs/TapDark'
import { useSpring } from '@react-spring/web'
import { a as animated } from '@react-spring/web'
import HUD from '../components/HUD'
import Orientation from '../components/Orientation'
import { isMobile } from 'react-device-detect'
import ErrorIcon from '../svgs/ErrorIcon'

const SingleProduct = () => {
  let { productCode } = useParams()
  // const skyboxes = ['whiteroom', 'darkroom']
  const [skyboxes, setSkyboxes] = useState([])
  const [modalData, setModalData] = useState([])
  const contactShadow = modalData.contactShadow
  const view = modalData.view
  const landscapeView = modalData.landscapeView
  const portraitView = modalData.portraitView
  const controls = useRef()
  const [showTapText, setShowTapText] = useState(null)
  const [showProductFeature, setShowProductFeature] = useState(false)
  const [isDark, setIsDark] = useState(false)
  const [hovered, setHovered] = useState(false)
  const modelPointerDownHandler = useRef()
  const qr = useQR()
  const [dimension, setDimension] = useState(false)
  const [showHud, setShowHud] = useState(false)
  const [dimensionStatus, setDimensionStatus] = useState('open')
  const [tooltipTutorial, setTooltipTutorial] = useState(false)
  // const [loadingModel, setLoadingModel] = useState(false)
  // const [loadingHdr, setLoadinHdr] = useState(false)
  const [loading, setLoading] = useState(0)
  const [orientation, setOrientation] = useState(null)

  const [position, setPosition] = useState(null)
  const [zoom, setZoom] = useState(null)

  const HudFadeAnim = useSpring({
    opacity: showHud ? 1 : 0,
    background: `${isDark ? 'rgba(255,255, 255,0.4)' : 'rgba(0,0, 0,0.2)'}`,
    config: { duration: 800 },
  })

  const TapFadeAnim = useSpring({
    opacity: showTapText === 'active' ? 1 : 0,
    config: { duration: 800 },
  })

  const FeatureTapFadeAnim = useSpring({
    opacity: showProductFeature ? 1 : 0,
    config: { duration: 800 },
  })

  const [hdriLoaded, setHdriLoaded] = useState(false)
  const [modelLoaded, setModelLoaded] = useState(false)

  const Loader = () => {
    const { progress } = useProgress()
    const [load, setLoad] = useState(true)
    const animationStore = useHud()

    useEffect(() => {
      let timer = null
      if (progress === 100) {
        timer = setTimeout(() => {
          setLoad(false)
          if (!isDark && showTapText === null) setShowTapText('active')
        }, 800)
      }
      return () => clearTimeout(timer)
    }, [progress, isDark, showTapText])

    const loadAnim = useSpring({
      opacity: progress === 100 ? 0 : 1,
      config: { duration: 200 },
    })

    return (
      <>
        {progress != 100 && (
          <Html fullscreen style={{ pointerEvents: 'none' }}>
            {/* <h1>{progress.toFixed(3) + '%'}</h1> */}
            <animated.div className={css(styles.loaderContainer)}>
              <div className={css(styles.loader)}></div>
            </animated.div>
          </Html>
        )}
      </>
    )
  }

  useEffect(() => {
    const fetchModal = async () => {
      try {
        let res = await axios.get(`${baseUrl}/threed-model/${productCode}`)
        console.log('res single model', res, res.data)
        setModalData(res.data)
        setTimeout(() => {
          setModelLoaded(true)
        }, 5000)
      } catch (err) {
        console.error(err)
      }
      // setLoadingModel(false)
    }

    fetchModal()
  }, [])

  useEffect(() => {
    const fetchHdri = async () => {
      // setLoadinHdr(true)
      try {
        let response = await axios.get(baseUrl + '/skybox')
        // console.log('skyboxes in cms', skyboxes, response)
        setSkyboxes(response.data.map((x) => x.name))
        // console.log('fully loaded')
        setTimeout(() => {
          setHdriLoaded(true)
        }, 5000)
      } catch (err) {
        console.log('sky box error: ', err)
      }
      // setLoadinHdr(false)
    }

    fetchHdri()
  }, [modalData])

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  // useEffect(() => {
  //   console.log('single madal data: ', modalData)
  // }, [modalData])

  //! Model Zoom & Position
  useEffect(() => {
    if (isMobile) {
      if (orientation === 'portrait') {
        if (portraitView != null && portraitView.position != null) {
          // console.log('portrait view')
          setPosition(portraitView.position)
        } else {
          // console.log('else portrait view')
          setPosition(view?.position)
        }
      } else if (orientation === 'landscape') {
        if (landscapeView != null && landscapeView.position != null) {
          console.log('landscape view')
          setPosition(landscapeView.position)
        } else {
          // console.log('else landscape view')
          setPosition(view?.position)
        }
      }
    } else {
      setPosition(view?.position)
    }
  }, [orientation, isMobile, modalData])

  useEffect(() => {
    if (isMobile) {
      if (orientation === 'portrait') {
        if (portraitView != null && portraitView.zoom != null) {
          setZoom(portraitView.zoom)
        } else {
          setZoom(view?.zoom)
        }
      } else if (orientation === 'landscape') {
        if (landscapeView != null && landscapeView.position != null) {
          setZoom(landscapeView.zoom)
        } else {
          setZoom(view?.zoom)
        }
      }
    } else {
      setZoom(view?.zoom)
    }
  }, [orientation, isMobile, modalData])

  // useEffect(() => {
  //   console.log('position: ', position, 'zoom: ', zoom)
  // }, [zoom, position])

  return (
    <>
      <Orientation orientation={orientation} setOrientation={setOrientation} />

      <ErrorBoundary
        FallbackComponent={() => (
          <p className={css(styles.badglb)}>
            <div style={{ width: '8vw' }}>
              <ErrorIcon />
            </div>
            Could not load the 3D Model
          </p>
        )}
      >
        <Canvas shadows>
          <Loader />
          {hdriLoaded && modelLoaded && (
            <Suspense fallback={null}>
              <OrbitControls
                ref={controls}
                enablePan={false}
                minPolarAngle={-Math.PI / 2}
                maxPolarAngle={Math.PI / 1.9}
                rotateSpeed={0.5}
                minDistance={modalData.minZoom}
                maxDistance={modalData.maxZoom}
                // target={[0, 8.63, 0]}
                // target={[0, 2, 0]}
              />
              <Camera position={position} zoom={zoom} controls={controls} />
              <ContactShadows
                {...contactShadow}
                position={[
                  contactShadow?.x,
                  contactShadow && contactShadow.y != 0
                    ? contactShadow.y
                    : modalData.offsetY, // added -10 bcz of offset of the model
                  contactShadow?.z,
                ]}
                opacity={contactShadow?.opacity}
                scale={contactShadow?.scale}
                blur={contactShadow?.blur}
                depthWrite={true}
              />
              <Background isDark={isDark} />
              <fog
                attach="fog"
                color={`${isDark ? 'rgb(152,153,165)' : 'rgb(247,247,247)'}`}
                // color={'rgb(247,247,247)'}
                near={130}
                far={250}
              />
              <Environment
                files={
                  baseUrl +
                  '/skybox/' +
                  (isDark ? modalData.nightHDRI : modalData.dayHDRI)
                }
                // files={baseUrl + '/skybox/' + (isDark ? 'darkroom' : 'whiteroom')}
              />

              <ProductModel
                productCode={productCode}
                modelPointerDownHandler={modelPointerDownHandler}
                setHovered={setHovered}
                setShowProductFeature={setShowProductFeature}
                setShowTapText={setShowTapText}
                productDimension={dimension}
                setShowHud={setShowHud}
                setTooltipTutorial={setTooltipTutorial}
                isDark={isDark}
                modalData={modalData}
                dimension={dimension}
                setDimension={setDimension}
                setDimensionStatus={setDimensionStatus}
                dimensionStatus={dimensionStatus}
                controls={controls}
                showHud={showHud}
                showTapText={showTapText}
                showProductFeature={showProductFeature}
              />

              {/* </Stage> */}
            </Suspense>
          )}
        </Canvas>
      </ErrorBoundary>

      <HUD
        appleUrl={baseUrl + `/threed-model/usdz/${productCode}/model.usdz`}
        androidURL={baseUrl + `/threed-model/model/${productCode}/model.glb`}
        isDark={isDark}
        dimension={dimension}
        setDimension={setDimension}
        setDimensionStatus={setDimensionStatus}
        dimensionStatus={dimensionStatus}
        setIsDark={setIsDark}
        showHud={showHud}
      />

      {/* Click & Drag Tip */}
      <animated.div className={css(styles.hudTapContainer)} style={TapFadeAnim}>
        <div className={css(styles.hudTap)}>
          {isDark ? <TapDark /> : <Tap />}
        </div>
        <p
          className={css(styles.toolTip)}
          style={{
            background: isDark ? '#ffffff' : '#000000',
            color: isDark ? '#000000' : '#ffffff',
          }}
        >
          Click and drag to see the model
        </p>
      </animated.div>

      {/* Feature Tip */}
      <animated.div
        className={css(styles.hudTapContainer)}
        style={FeatureTapFadeAnim}
      >
        <div className={css(styles.hudTapProduct)}>
          {isDark ? <TapDark /> : <Tap />}
        </div>
        <p
          className={css(styles.toolTip)}
          style={{
            background: isDark ? '#ffffff' : '#000000',
            color: isDark ? '#000000' : '#ffffff',
          }}
        >
          Tap to see the product's feature
        </p>
      </animated.div>

      {/* QR Code Modal */}
      {qr.url && (
        <QRModal
          setDimensionStatus={setDimensionStatus}
          dimensionStatus={dimensionStatus}
          setDimension={setDimension}
        />
      )}
    </>
  )
}

export default SingleProduct

const translateKeyframes = {
  '0%': {
    transform: 'translateX(120px)',
  },

  '25%': {
    transform: 'translateX(230px)',
  },

  '75%': {
    transform: 'translateX(0)',
  },

  '100%': {
    transform: 'translateX(120px)',
  },
}

const scaleKeyframes = {
  '0%': {
    transform: 'scale(0.9)',
  },
  '50%': {
    transform: 'scale(1.2)',
  },
  '100%': {
    transform: 'scale(0.9)',
  },
}

const loadSpinnerKeyFrames = {
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
}

const styles = StyleSheet.create({
  lottie: {
    width: '11vw',
    '@media(min-width: 1400px)': {
      width: '8vw',
    },
  },
  toolTip01: {
    // width: '7rem',
    background: 'rgba(0, 0, 0, .7)',
    // borderRadius: 5,
    textAlign: 'center',
    padding: '0.2rem',
    fontSize: '0.75rem',
    color: '#fff',
    margin: 0,
    position: 'absolute',
    top: '-48%',
    whiteSpace: 'nowrap',
    display: 'none',
    ':before': {
      content: "''",
      border: '.4rem solid rgba(0, 0, 0, .7)',
      borderTopColor: 'none',
      borderRightColor: 'none',
      position: 'absolute',
      top: '70%',
      left: '10%',
      transform: 'translateX(-50%) rotate(-45deg)',
      // zIndex: -99,
    },
  },
  measureButton: {
    // position: 'absolute',
    // left: '1.8vh',
    // bottom: '1vh',
    marginLeft: '1.5vh',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  button: {
    padding: 0,
    background: 'none',
    outline: 'none',
    // width: `4.7vw`,
    width: 'clamp(4.5vw, 9.8vh, 30px)',
    height: 'auto',
    textAlign: 'center',
    margin: 0,
    border: 'none',

    ':hover': {
      cursor: 'pointer',
    },
    '@media(min-width: 1400px)': {
      width: '3.9vw',
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',
    position: 'absolute',
    inset: 0,
    // width: 100,
    // height: 100,
    // inset: 0,
  },
  hud: {
    position: 'absolute',
    paddingLeft: '3.3vh',
    paddingRight: '1.5vh',
    paddingTop: '1vh',
    bottom: '3vh',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '1.5vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // left: '40%',
    '@media (max-width: 576px) and (orientation: portrait)': {
      bottom: '5vh',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      bottom: '5vw',
    },
    '@media (min-width: 1200px)': {
      bottom: '3vh',
    },
  },
  toolTip: {
    // background: '#000000',
    // color: '#ffffff',
    padding: '10px 30px',
    whiteSpace: 'nowrap',
    margin: 0,
    marginTop: '2vh',
    fontSize: '1rem',
    // borderRadius: 20,
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        fontSize: '2vh',
      },
    '@media (min-width: 1200px)': {
      fontSize: '1.2vw',
    },
    '@media (min-width: 1920px)': {
      fontSize: '1.2vw',
    },
  },
  animationToolTip: {
    background: 'rgb(38, 138, 204)',
    color: '#ffffff',
  },
  theme: {
    position: 'fixed',
    top: '2%',
    right: '1%',
    padding: 5,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
  },
  themeSun: {
    background: '#ffffff',
    marginRight: 20,
  },
  themeMoon: {
    background: '#000000',
  },
  light: {
    background: '#ffffff',
  },
  dark: {
    background: '#000000',
  },
  hudAR: {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    marginTop: '-0.5vh',
  },
  hudTapContainer: {
    position: 'absolute',
    bottom: '3%',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
  },
  hudTap: {
    animationName: translateKeyframes,
    animationDuration: '7s',
    animationIterationCount: 'infinite',
    // width: 'fit-content',
    width: 'clamp(3.2rem, 3.3vw, 40px)',
    height: 'auto',
    // left: '120px',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        width: '6vh',
      },
    '@media (min-width: 1200px)': {
      width: '3.3vw',
    },
    '@media (min-width: 1920px)': {
      fontSize: '3.5vw',
    },
  },
  hudTapProduct: {
    textAlign: 'center',
    animationName: scaleKeyframes,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'clamp(3.2rem, 3.3vw, 40px)',
    height: 'auto',
    '@media(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)':
      {
        width: '6vh',
      },
    '@media (min-width: 1200px)': {
      width: '3.3vw',
    },
    '@media (min-width: 1920px)': {
      fontSize: '3.5vw',
    },
  },
  btn: {
    margin: 0,
    border: 0,
    background: 'none',
    lineHeight: 0,
    // padding: 3,
    borderRadius: '50%',
    ':hover': {
      cursor: 'pointer',
    },
  },
  themeBtn: {
    // position: 'fixed',
    // top: '0',
    // right: '1%',
    // width: '11vw',
    width: 'clamp(11vw, 9.8vh, 120px)',
    padding: 0,
    overflow: 'auto',
    // marginRight: -2,

    // width: '5.5vw',
    // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    ':hover + p': {
      display: 'block',
    },
    '@media(min-width: 1400px)': {
      width: '8.8vw',
    },
  },
  badglb: {
    fontSize: '2rem',
    fontWeight: 'bold',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  skyboxContainer: {
    // position: 'fixed',
    // top: '1.5%',
    // right: '0.8vh',
    // textAlign: 'center',
    // marginLeft: '0.8vh',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    mariginLeft: '0.3vh',
  },
  measureTooltipContainer: {
    position: 'absolute',
    top: '-25%',
  },
  measureTooltipTrinangle: {
    width: 0,
    height: 0,
    borderLeft: '.6rem solid transparent',
    borderRight: '.6rem solid transparent',
    // borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
    marginLeft: '2vh',

    position: 'absolute',
    marginTop: 25,
    borderTop: '.8rem solid rgba(0, 0, 0, .7)',
  },
  toolTipContainer: {
    position: 'absolute',
    // right: 0,
    // marginTop: -10,
    top: '-25%',
    marginLeft: '8%',
  },
  toolTipTriangle: {
    width: 0,
    height: 0,
    borderLeft: '.6rem solid transparent',
    borderRight: '.6rem solid transparent',
    // borderBottom: '.8rem solid rgba(0, 0, 0, .7)',
    marginLeft: '2vh',
    // position: 'absolute',

    position: 'absolute',
    marginTop: 25,
    borderTop: '.8rem solid rgba(0, 0, 0, .7)',
  },
  skyboxToolTip: {
    display: 'block',
    whiteSpace: 'nowrap',
    // width: '7rem',
    background: 'rgba(0, 0, 0, .7)',
    // borderRadius: 5,
    textAlign: 'center',
    padding: '0.35rem 0.9rem',
    fontSize: '0.75rem',
    color: '#fff',
    // marginTop: '0rem',
    // position: 'absolute',
    // right: 0,
    // ':before': {
    //   content: "''",
    //   border: '.4rem solid rgba(0, 0, 0, .7)',
    //   borderBottomColor: '#fff',
    //   borderRightColor: '#fff',
    //   position: 'absolute',
    //   top: '-28%',
    //   left: '50%',
    //   transform: 'translateX(-50%) rotate(45deg)',
    //   zIndex: '-99',
    // },
  },
  loader: {
    fontSize: 10,
    margin: '50px auto',
    textIndent: '-9999em',
    width: '11em',
    height: '11em',
    borderRadius: '50%',
    // background: 'linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%)',
    background:
      'linear-gradient(to right, #000 10%, rgba(230, 230, 230, 0) 42%)',
    position: 'relative',
    animationName: loadSpinnerKeyFrames,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    ':before': {
      width: '50%',
      height: '50%',
      // background: '#ffffff',
      background: '#000',
      // background: '#000',
      borderRadius: '100% 0 0 0',
      position: 'absolute',
      top: 0,
      left: 0,
      content: "''",
    },
    ':after': {
      background: '#fff',
      width: '75%',
      height: '75%',
      borderRadius: '50%',
      content: "''",
      margin: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
})

const lottieStyles = {
  width: '11vw',
  '@media(min-width: 1400px)': {
    width: '8vw',
  },
}
