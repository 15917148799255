import create from 'zustand'

export const useQR = create((set) => ({
  url: '',
  setQR: (givenURL) => set(() => ({ url: givenURL })),
}))

// export const baseUrl = 'https://godrej-interio.autovrse.app'
// export const baseUrl = 'https://ppautovrse.godrej.com/api'
// http://localhost:5000
export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://godrej-interio.autovrse.app'
    : 'https://godrej-interio.autovrse.app'

export const useModal = create((set) => ({
  show: false,
  data: {},
  setShow: (pos) => set(() => ({ show: pos })),
  setData: (givenData) => set(() => ({ data: givenData })),
}))

export const useHud = create((set) => ({
  isPlaying: false,
  setIsPlaying: (bool) => set(() => ({ isPlaying: bool })),
}))

export const getImageUrl = async (code) => {
  let imageUrl = baseUrl + '/threed-model/thumbnail/' + code
  return imageUrl
}

export const thumbnailUrl = baseUrl + '/threed-model/thumbnail/'
